import { PageTitle } from "@/ui/texts/PageTitle";
import { Box, Button, FormControl, Input, Text } from "@suit-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CourtSelect from "./CourtSelect";
import { useNavigate } from "react-router-dom";
import { routes } from "@/router";
import UsersSelect from "@/features/credentials/components/UsersSelect";
import { useEffect } from "react";
import { useGetCustomFields } from "@/features/customFields/useCases/useGetCustomFields";
import { CustomFieldInput } from "@/features/customFields/components/CustomFieldInput";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { trackEvent } from "@/utils/analytics/trackEvent";

const schema = z
  .object({
    title: z.string().min(1),
    code: z.string().min(1),
    court_id: z.string().optional(),
    user_ids: z.string().array().optional(),
    custom_data: z.any(),
  })
  .required({ title: true, code: true, court_id: true });

export type CaseFormData = z.infer<typeof schema>;

interface CaseFormProps {
  onSave: (ccase: CaseFormData) => Promise<void>;
  isLoading?: boolean;
  ccase: CaseFormData;
  readonlyFields?: string[];
  onSaveFailed: (error: string | null) => void;
}

const CaseForm = ({
  onSave,
  isLoading,
  ccase,
  readonlyFields = [],
  onSaveFailed,
}: CaseFormProps) => {
  const { t } = useTranslation();
  const { customFields } = useGetCustomFields("Case");
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CaseFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
    defaultValues: { ...ccase },
  });

  const navigate = useNavigate();
  const goToCases = () => navigate(routes.cases);
  const onSubmit = async (caseData: CaseFormData) => {
    onSaveFailed(null);
    try {
      await onSave(caseData);
      trackEvent("Create case form submitted");
    } catch (error) {
      onSaveFailed((error as any).response.data.error);
    }
  };

  useEffect(() => {
    reset({ ...ccase });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccase]);

  return (
    <>
      <PageTitle>{t("cases.case_form_title")}</PageTitle>

      <Box className="flex justify-center w-full">
        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col bg-neutral-50 p-8 rounded-sm shadow-xs mt-6 w-full max-w-screen-md gap-6"
          noValidate
        >
          <FormControl required isInvalid={!!errors.title}>
            <FormControl.Label>{t("cases.title")}</FormControl.Label>
            <Input
              {...register("title", {
                required: true,
              })}
              disabled={readonlyFields.includes("title")}
            />
            {!!errors.title && (
              <FormControl.ErrorMessage>
                {t(`form_errors.field_required`)}
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl required isInvalid={!!errors.code}>
            <FormControl.Label>{t("cases.code")}</FormControl.Label>

            <Input
              {...register("code", {
                required: true,
              })}
              disabled={readonlyFields.includes("code")}
            />

            {!!errors.code && (
              <FormControl.ErrorMessage>
                {t(`form_errors.field_required`)}
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.court_id}>
            <FormControl.Label>{t("cases.court")}</FormControl.Label>

            <Controller
              control={control}
              name="court_id"
              render={({ field: { onChange, onBlur, value } }) => (
                <CourtSelect
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={readonlyFields.includes("court_id")}
                />
              )}
            />

            {!!errors.court_id && (
              <FormControl.ErrorMessage>
                {t(errors.court_id.message!)}
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl>
            <FormControl.Label>{t("cases.user_ids")}</FormControl.Label>

            <Controller
              control={control}
              name="user_ids"
              disabled={readonlyFields.includes("user_ids")}
              render={({ field }) => <UsersSelect {...field} />}
            />

            {!!errors.user_ids && (
              <FormControl.ErrorMessage>
                {t(errors.user_ids.message!)}
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          {customFields
            .filter((customField) => customField.display_order_form > 0)
            .map((customField) => (
              <FormControl key={`case_custom_field_${customField.name}`}>
                <FormControl.Label>{customField.name}</FormControl.Label>

                <Controller
                  control={control}
                  name={`custom_data.${customField.name}`}
                  render={({ field }) => (
                    <CustomFieldInput
                      {...field}
                      id={customField.id}
                      disabled={readonlyFields.includes(
                        `custom_data.${customField.name}`,
                      )}
                      name={customField.name}
                      model="Case"
                    />
                  )}
                />
              </FormControl>
            ))}

          <Box className="flex items-center gap-5">
            <Text className="mr-auto">* {t("ui.required_fields")}</Text>

            <Button
              variant="text"
              disabled={isLoading}
              onClick={() => {
                trackEvent("Create case form canceled");
                goToCases();
              }}
            >
              {t("ui.cancel")}
            </Button>

            <Button type="submit" loading={isLoading}>
              {t("cases.create_case")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { CaseForm };
