// eslint-disable-next-line
// @ts-nocheck
import { useContext } from "react";
import PropTypes from "prop-types";
import { useTreatments, SplitContext } from "@splitsoftware/splitio-react";

// eslint-disable-next-line
const TREATMENTS = { control: "control", on: "on", off: "off" };

export const ffValuesParsed = (treatments: any) => {
  const parsedObject = {} as any;

  for (const treatment in treatments) {
    // eslint-disable-next-line
    if (treatments.hasOwnProperty(treatment)) {
      parsedObject[treatment] = treatments[treatment] === TREATMENTS.on;
    }
  }

  return parsedObject;
};

const withFeatureFlags = (ffNames) => (Component) => (props) => {
  const getValues = () => {
    // eslint-disable-next-line
    const { isReady, isTimedout } = useContext(SplitContext);
    // eslint-disable-next-line
    const treatments = useTreatments(ffNames) || {};

    if (isTimedout) {
      console.warn("Split SDK is not ready");
      return treatments;
    }

    if (!isReady) return treatments;

    handleControlTreatments(treatments);

    return treatments;
  };

  const isEnabled = (ffValue) => ffValue === TREATMENTS.on;

  const handleControlTreatments = (treatments) => {
    const ffControl = Object.keys(treatments).filter(
      (ffName) => treatments[ffName].treatment === TREATMENTS.control,
    );

    if (ffControl.length > 0)
      throw new Error(`Invalid ff for '${ffControl.join(",")}'`);
  };

  const ffValues = getValues();
  const ffValuesParsed = Object.keys(ffValues).reduce((ffs, ffName) => {
    ffs[ffName] = isEnabled(ffValues[ffName].treatment);
    return ffs;
  }, {});

  return (
    <Component
      data-test-id="child-component"
      ffValues={ffValuesParsed}
      {...props}
    />
  );
};

withFeatureFlags.propTypes = {
  Component: PropTypes.element.isRequired,
  ffNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  props: PropTypes.object.isRequired,
};

export default withFeatureFlags;
