import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";
import { useTranslation } from "react-i18next";
import { useToast } from "@suit-ui/react";
import { AxiosError } from "axios";
interface ScraperParams {
  [key: string]: Record<string, unknown> | string;
}

interface ConnectCaseParams {
  scraperParams: ScraperParams;
  credentialId?: string | undefined;
  spodySearchKey: string;
  /** If omitted, when connecting a case fails a toast will be shown with the error message */
  onError?: (message: string) => void;
}

const connectCase =
  (caseId: string) =>
  async ({
    scraperParams,
    spodySearchKey,
    credentialId,
  }: ConnectCaseParams) => {
    const response = await fetcher.post(`/cases/${caseId}/connect`, {
      key: spodySearchKey,
      scraper_params: scraperParams,
      credential_id: credentialId,
    });
    return response.data;
  };

interface HookParams {
  caseId: string;
}

export const useCreateConnectionOfflineCase = ({ caseId }: HookParams) => {
  const toast = useToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: connectCase(caseId),
    onSuccess: () => {
      toast.add({
        content: t("cases.connect_case_success"),
        status: "success",
      });
    },

    onError: (error: AxiosError<{ message: string }>, params: ConnectCaseParams) => {
      const errorMessage =
        error?.response?.data?.message || t("ui.unexpected_error");
      if (params.onError) {
        params.onError(errorMessage);
      } else {
        toast.add({
          content: errorMessage,
          status: "danger",
        });
      }
    },
  });
};

