import { Dialog, Box, Text, Button, Link } from "@suit-ui/react";
import { format } from "date-fns";
import { TERMS_OF_USE_URL } from "@/configs";
import { signOut } from "../useCases/signOut";
import { useUpdateSetting } from "../useCases/useUpdateSetting";
import { useTranslation } from "react-i18next";

const TermsOfUseModal = () => {
  const { t } = useTranslation();
  const { updateSetting } = useUpdateSetting(false);

  const handleAcceptTerms = async () => {
    const acceptDate = format(new Date(), "yyyy-MM-dd HH:mm:ss 'UTC'")
    updateSetting({
      code: 'properties_terms_of_use_acceptance_date',
      value: acceptDate,
    });
  };

  return (
    <Dialog
      isOpen
      size="sm"
      status="info"
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <Dialog.Overlay />
      <Dialog.Content>
        <Dialog.Header>{t("terms_of_use.title")}</Dialog.Header>
        <Dialog.Body>
          <Box className="flex items-center"></Box>
          <Text>
            {t("terms_of_use.description")}
            <Link
              className="text-primary-500 hover:text-primary-600"
              href={TERMS_OF_USE_URL}
              isExternal
              variant="unstyled"
              disableVisitedStyle={true}
            >
              {t("terms_of_use.link")}
            </Link>
            {t("terms_of_use.description_2")}
          </Text>
        </Dialog.Body>
        <Dialog.Footer>
          <Button variant="text" color="neutral" onClick={signOut}>
            {t("terms_of_use.close_session")}
          </Button>
          <Button
            className="ml-2"
            variant="solid"
            color="primary"
            onClick={handleAcceptTerms}
          >
            {t("terms_of_use.accept")}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
};

export default TermsOfUseModal;
