import { FormProvider, useForm } from "react-hook-form";
import { Modal } from "@suit-ui/react";
import { CaseWithConnectionForm } from "@/features/cases/types/CaseWithConnectionForm";
import { SearchCaseForm } from "./SearchCaseForm";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onCompletion?: () => void;
  mode: "creating" | "connecting";
}

export const ConnectCaseModal = ({
  mode,
  isOpen,
  closeModal,
  onCompletion,
}: Props) => {
  const [connectCaseFormResults, setConnectCaseFormResults] =
    useLocalStorage<CaseWithConnectionForm>("connectCaseFormResults");
  const methods = useForm<CaseWithConnectionForm>(
    connectCaseFormResults
      ? {
          values: connectCaseFormResults,
        }
      : {
          defaultValues: {
            finderId: "",
          },
        },
  );
  const { t } = useTranslation();

  const onSearchComplete = () => {
    setConnectCaseFormResults(methods.getValues());
    onCompletion?.();
  };

  const onCancel = () => {
    localStorage.removeItem("connectCaseFormResults");
    methods.reset({ finderId: "" });
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="lg">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>{t("connect_case_modal.title")}</Modal.Header>
        <Modal.CloseButton />
        <Modal.Body>
          <FormProvider {...methods}>
            <SearchCaseForm
              cancelButtonLabel={mode === "creating" ? t("ui.back") : undefined}
              onStepComplete={onSearchComplete}
              onCancel={onCancel}
            />
          </FormProvider>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
