import { Box, Button, useDisclosure } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { MdPublicOff } from "react-icons/md";
import { ConnectCaseModal } from ".";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from 'react';
import { trackEvent } from '@/utils/analytics/trackEvent';

export const ConnectCaseBtn = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure(!!searchParams.get("showConnectModal"));
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(searchParams.get("showConnectModal")) {
      searchParams.delete("showConnectModal");
      setSearchParams(searchParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onBtnClick = () => {
    trackEvent("Case detail page: Connect case button clicked");
    onOpen();
  };

  const onFormCompletion = () => {
    navigate(`${location.pathname}/connect`);
  };

  return (
    <>
      <Button variant="outline" onClick={onBtnClick}>
        <Box className="mr-2">
          <MdPublicOff size={24} />
        </Box>
        {t("cases.header.connect_case")}
      </Button>

      <ConnectCaseModal
        mode="connecting"
        isOpen={isOpen}
        closeModal={onClose}
        onCompletion={onFormCompletion}
      />
    </>
  );
};

