import { Placeholder, Button } from '@suit-ui/react'
import { t } from 'i18next'
import { PageContainer } from '../../../ui/containers/PageContainer'
import { PageTitle } from '../../../ui/texts/PageTitle'

export interface NoCaseResultsPlaceholderProps {
  title?: string,
  description?: string,
  onClick: () => void,
  buttonLabel: string
}

export function NoCaseResultsPlaceholder({
  title,
  onClick,
  buttonLabel,
  description}: NoCaseResultsPlaceholderProps) {
  return (
    <PageContainer className="flex flex-col items-center">
      {title && <PageTitle className="mt-2 self-start">{title}</PageTitle>}
      <Placeholder className="w-[600px]">
        <Placeholder.Image className="mt-12">
          <Placeholder.EmptyFileIcon />
        </Placeholder.Image>
        <Placeholder.Content>
          <Placeholder.Title>
            {t("cases.connect_search_not_found_title")}
          </Placeholder.Title>
          {description && <Placeholder.Description>{description}</Placeholder.Description>}
        </Placeholder.Content>
        <Placeholder.BtnGroup>
          <Button onClick={onClick} size="sm">{buttonLabel}</Button>
        </Placeholder.BtnGroup>
      </Placeholder>
    </PageContainer>
  )
}
