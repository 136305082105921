import mixpanel from "mixpanel-browser";

declare global {
  interface Window {
    Appcues: any;
  }
}

interface IdentifyParams {
  id: string;
  role: string;
  tenant: string;
  email: string;
  country: string;
}

export const identifyTrackedUser = ({
  id,
  role,
  tenant,
  email,
  country,
}: IdentifyParams) => {
  mixpanel.identify(id);
  mixpanel.people.set({ role, email, tenant });

  mixpanel.register({ source: "web-app" });

  window.Appcues.identify(id, { email, role, tenant, country });
};
