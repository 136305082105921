import { PageTitle } from "@/ui/texts/PageTitle";
import { useTranslation } from "react-i18next";
import { CasesToConnectTable } from "../CasesToConnectTable";

interface SearchResultStepProps {
  onStepComplete?: () => void;
}

export const SearchResultStep = ({ onStepComplete }: SearchResultStepProps) => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle className="bg-neutral-100 mb-11">
        {t("cases.header.connect_case")}
      </PageTitle>
      <CasesToConnectTable
        mode="creating"
        skipConfirmation
        onStepComplete={onStepComplete}
      />
    </>
  );
};

