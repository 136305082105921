import { Select, SelectProps } from "@suit-ui/react";
import { useMemo } from "react";
import { useGetClients } from "@/features/clients/useCases/useGetClients";

interface ClientSelectProps extends SelectProps {
  onChange?: (value: string) => void;
  onBlur?: () => void;
  value?: string;
  disabled?: boolean;
}

const ClientSelect: React.FC<ClientSelectProps> = ({
  onChange,
  onBlur,
  value,
  disabled,
  className = "",
  ...rest
}) => {
  const { data: clients, isLoading } = useGetClients();
  const clientsOptions = useMemo(() => {
    return clients?.map((client) => ({
      label: client.name,
      value: client.id,
    }));
  }, [clients]);

  return (
    <Select
      className={className}
      menuPosition="fixed"
      valueAsObject={false}
      value={value}
      options={clientsOptions || []}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
      isLoading={isLoading}
      isClearable
      {...rest}
    />
  );
};

export default ClientSelect;
