import { ThemeProvider } from "@suit-ui/react";
import { AuthProvider } from "./features/session/providers/AuthProvider";
import TermsOfUseModal from "./features/session/components/TermsOfUseModal";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SplitFactoryProvider } from "@splitsoftware/splitio-react";
import { splitSetup } from "./utils/analytics/splitSdk";
import { getTenantName } from "./features/session/useCases/getTenantName";
import { useGetSettings } from "./features/session/useCases/useGetSettings";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
    },
  },
});

export interface ProvidersProps {
  children?: React.ReactNode;
}

export const Providers = ({ children }: ProvidersProps) => {
  const config = splitSetup(getTenantName());

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <SplitFactoryProvider config={config}>
          <AuthProvider>
            {children}
            <CurrentUserTermsOfUse />
          </AuthProvider>
        </SplitFactoryProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

const CurrentUserTermsOfUse = () => {
  const { settings } = useGetSettings();
  const shouldShowTermsOfUseModal =
    settings &&
    settings.merged.features_enable_terms_of_use_modal &&
    !settings.merged.properties_terms_of_use_acceptance_date;

  return shouldShowTermsOfUseModal ? <TermsOfUseModal /> : null;
};
