import { PageContainer } from "@/ui/containers/PageContainer";
import { BreadcrumbNav } from "@/ui/navbars/BreadcrumbNav";
import { Box, Button, Icon } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdOutlineReportProblem } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useCreateCase } from "../useCases/useCreateCase";
import { CaseForm } from "../components/CaseForm";
import { Case } from "../types/Case";
import { routes } from "@/router";
import { useState } from "react";
const defaultCase: Pick<
  Case,
  "id" | "title" | "court_id" | "code" | "user_ids"
> = {
  id: "",
  title: "",
  court_id: "",
  code: "",
  user_ids: [],
};

export function CaseWithoutConnectionPage() {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useCreateCase();
  const [caseError, setCaseError] = useState<string | null>(null);
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <>
      {caseError && (
        <Box className="flex items-center bg-danger-200 p-4">
          <Icon as={MdOutlineReportProblem} />

          <Box className="font-regular ml-2">{caseError}</Box>

          <Button
            variant="text"
            className="ml-auto font-semibold"
            onClick={() => setCaseError(null)}
          >
            {t("ui.close")}
          </Button>
        </Box>
      )}
      <PageContainer className="flex flex-col items-start">
        <Box className="flex justify-between mb-6">
          <BreadcrumbNav />
        </Box>

        <Button
          variant="text"
          className="mb-6 min-h-10"
          size="md"
          leftIcon={<MdArrowBackIos />}
          onClick={goBack}
        >
          {t("ui.back")}
        </Button>

        <CaseForm
          onSave={async (data) => {
            const { id } = await mutateAsync(data);
            navigate(`${routes.cases}/${id}`);
          }}
          isLoading={isPending}
          ccase={defaultCase}
          onSaveFailed={(error) => setCaseError(error)}
        />
      </PageContainer>
    </>
  );
}
