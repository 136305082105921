import { fetcher } from "@/utils/fetchers/fetcher";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "@suit-ui/react";
import { Project } from "../types/Project";
import { GET_PROJECTS_FETCH_KEY } from "./useGetProjects";

const createProject = async (data: Partial<Project>) => {
  const response = await fetcher.post<Project>("/projects", { project: data });
  return response.data;
};

export const useCreateProject = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toast = useToast();

  const { mutateAsync, isPending, isError } = useMutation({
    mutationFn: async (data: Partial<Project>) => createProject(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_PROJECTS_FETCH_KEY],
      });
      toast.add({
        content: t("projects.project_created_successfully"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });

  return { createProject: mutateAsync, isPending, isError };
};

