export const SSO_URL = import.meta.env.VITE_SSO_URL ?? "";
export const GREEN_MARIO_API_URL: string =
  import.meta.env.VITE_GREEN_MARIO_API_URL ?? "";
export const GREEN_MARIO_COOKIE_API_URL: string =
  import.meta.env.VITE_GREEN_MARIO_COOKIE_API_URL ?? "";

export const MIXPANEL_TOKEN: string = import.meta.env.VITE_MIXPANEL_TOKEN ?? "";

export const SPLIT_API_KEY = import.meta.env.VITE_SPLIT_API_KEY || "localhost";

export const NODE_ENV = process.env.NODE_ENV;
export const X_CONSUMER_USERNAME = import.meta.env.VITE_X_CONSUMER_USERNAME;
export const IS_DEV = import.meta.env.MODE === "development";

export const TERMS_OF_USE_URL = import.meta.env.VITE_TERMS_OF_USE_URL ?? "";
