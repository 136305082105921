import { Box, Button, Table, useToast } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { useConnectCase } from "../../useCases/useConnectCase";
import { usePollConnectCaseResult } from "../../useCases/usePollConnectCaseResult";
import { useEffect } from "react";
import { MdOutlineCheck } from "react-icons/md";
import clsx from "clsx";

interface SearchResultItemProps {
  values: string[];
  scraper_params: { [key: string]: Record<string, unknown> | string };
  detail_cols: number[];
  col_names: string[];
  credential_id: string | undefined;
  isDisabled?: boolean;
  isConnected?: boolean;
  onConnect: (idx: number) => void;

  onSearchResultFinish: (data: any, values: string[]) => void;
  onReset: () => void;
  idx: number;
}

const SearchResultItem = ({
  values,
  scraper_params,
  detail_cols = [],
  col_names,
  credential_id,
  isDisabled = false,
  isConnected = false,
  onConnect,
  onSearchResultFinish,
  onReset,
  idx,
}: SearchResultItemProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const {
    data: connectCaseKey,
    mutateAsync: connectCase,
    isPending: isConnectingCase,
  } = useConnectCase(scraper_params);
  const { data: connectCaseResultData, isLoading: isPollingConnectCaseResult } =
    usePollConnectCaseResult(connectCaseKey?.key);

  const handleConnectCase = () => {
    if (connectCaseResultData) {
      onSearchResultFinish(
        {
          ...connectCaseResultData,
          scraper_params,
          key: connectCaseKey?.key,
        },
        values,
      );
      return;
    }

    const scraperParams: { [key: string]: any } = {};
    for (const key in scraper_params) {
      scraperParams[`scraper_params[${key}]`] = scraper_params[key];
    }
    connectCase({ scraper_params: scraperParams, credential_id });
    onConnect(idx);
  };

  useEffect(() => {
    if (connectCaseResultData && !connectCaseResultData?.error) {
      onSearchResultFinish(
        {
          ...connectCaseResultData,
          scraper_params,
          key: connectCaseKey?.key,
        },
        values,
      );
    }

    if (connectCaseResultData?.error) {
      onReset();
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectCaseResultData]);

  const hasDetailCols = detail_cols?.length > 0;

  return (
    <Table.Body className="odd:bg-neutral-100 even:bg-neutral-50">
      <Table.Row className={clsx(hasDetailCols && "border-b-0")}>
        {values.map(
          (val, valIdx) =>
            detail_cols.indexOf(valIdx) < 0 && (
              <Table.Cell
                key={`SearchResultItem-cell-${valIdx}`}
                className={clsx(
                  "whitespace-pre-wrap",
                  hasDetailCols ? "px-4" : "p-4",
                )}
              >
                {val}
              </Table.Cell>
            ),
        )}

        <Table.Cell rowSpan={hasDetailCols ? 2 : 1}>
          <Button
            className="gap-2 pl-3"
            variant="outline"
            onClick={handleConnectCase}
            disabled={isDisabled}
            loading={isConnectingCase || isPollingConnectCaseResult}
            loadingText={t("cases.search_result_connecting_case")}
            leftIcon={isConnected ? <MdOutlineCheck /> : undefined}
            color={isConnected ? "success" : "primary"}
          >
            {isConnected
              ? t("cases.search_result_connected_case")
              : t("cases.search_result_connect_case")}
          </Button>
        </Table.Cell>
      </Table.Row>

      {hasDetailCols && (
        <Table.Row className={clsx(hasDetailCols && "!border-b")}>
          <Table.Cell
            className="pt-0 pb-5"
            colSpan={values.length - detail_cols.length}
          >
            <Box className="flex flex-col gap-2">
              {detail_cols.map((colIdx) => (
                <Box key={colIdx}>
                  <Box as="strong" className="font-semibold">
                    {col_names[colIdx]}
                  </Box>
                  : {values[colIdx]}
                </Box>
              ))}
            </Box>
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  );
};

export { SearchResultItem };
