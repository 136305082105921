import {
  Box,
  Table,
  Text,
  Icon,
  Checkbox,
  Input,
  DatePicker,
  FilterButton,
  Dropdown,
  SelectMultiCheckbox,
} from "@suit-ui/react";
import { PageContainer } from "../../../ui/containers/PageContainer";
import { MovementFilter, useGetMovements } from "../useCases/useGetMovements";
import { BreadcrumbNav } from "@/ui/navbars/BreadcrumbNav";
import { PageTitle } from "@/ui/texts/PageTitle";
import { useTranslation } from "react-i18next";
import { useUserAccess } from "@/features/session/useCases/useUserAccess";
import { TableBody } from "@/ui/TableBody";
import { Link as RrLink } from "react-router-dom";
import { useGetCourts } from "@/features/cases/useCases/useGetCourts";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { MdSearch, MdOutlineArrowForward } from "react-icons/md";
import { useMemo, useState } from "react";
import { parseOfficialDate } from "../useCases/parseOfficialDate";
import { CreatorAvatar } from "../components/CreatorAvatar";
import { useGetCustomFields } from "@/features/customFields/useCases/useGetCustomFields";
import { getCustomFieldValueFormatted } from "@/features/customFields/useCases/getCustomFieldValueFormatted";
import { useDebounce } from "@/utils/debounce/useDebounce";
import { TableFooter } from "@/ui/TableFooter";
import { CustomFieldFilter } from "@/features/customFields/components/CustomFieldFilter";
import { endOfDay, format, parse, startOfDay } from "date-fns";
import { useFilters } from "@/utils/filter/useFilters";
import AttachmentLink from "@/features/cases/components/AttachmentLink";
import { useDateLocale } from "@/utils/i18n/useDateLocale";
import { trackEvent } from "@/utils/analytics/trackEvent";

export function MovementsPage() {
  const locale = useDateLocale();
  const { t } = useTranslation();
  const [filters, setFilters] = useFilters<MovementFilter>({
    case__active: {
      eq: true,
    },
    custom_data: {},
  });

  const {
    movements,
    isLoading,
    isError,
    isRefetching,
    pagination,
    nextPage,
    prevPage,
    onChangePageRows,
    queryKey,
    goToFirstPage,
  } = useGetMovements({ filters });
  const courts = useGetCourts();
  const users = useGetUsers();

  const { customFields } = useGetCustomFields("Movement");
  const hasPermission = useUserAccess("movements-read");

  const customFieldsInTable = [...customFields]
    .filter((field) => field.display_order_table > 0)
    .sort((a, b) => a.display_order_table - b.display_order_table);

  const customFieldsNotInTable = customFields?.filter(
    (field) => field.display_order_table == 0,
  );

  const customFieldsInFilter = [...customFields]
    .filter((field) => field.display_order_filter > 0)
    .sort((a, b) => a.display_order_filter - b.display_order_filter);

  const dateFilterFormat = "yyyy-MM-dd";
  const [startDate, setStartDate] = useState<Date | null>(
    filters?.official_date?.gte
      ? parse(filters?.official_date?.gte, dateFilterFormat, new Date())
      : null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    filters?.official_date?.lte
      ? parse(filters?.official_date?.lte, dateFilterFormat, new Date())
      : null,
  );
  const onChangeDate = (date: Date | null) => {
    if (selectType === "start") {
      setSelectType("end");
      setStartDate(date ? startOfDay(date) : null);
      if (typeof date === "undefined" || date === null) {
        return handleFilter({
          ...filters,
          official_date: {
            ...filters?.official_date,
            gte: undefined,
          },
          custom_data: {
            ...filters.custom_data,
          },
        });
      }
      return handleFilter({
        ...filters,
        official_date: {
          ...filters?.official_date,
          gte: format(date, dateFilterFormat),
        },
        custom_data: {
          ...filters.custom_data,
        },
      });
    }

    if (selectType === "end") {
      setEndDate(date ? endOfDay(date) : null);
      if (typeof date === "undefined" || date === null) {
        return handleFilter({
          ...filters,
          official_date: {
            ...filters?.official_date,
            lte: undefined,
          },
          custom_data: {
            ...filters.custom_data,
          },
        });
      }
      return handleFilter({
        ...filters,
        official_date: {
          ...filters?.official_date,
          lte: format(date, dateFilterFormat),
        },
        custom_data: {
          ...filters.custom_data,
        },
      });
    }
  };

  const [selectType, setSelectType] = useState("start");

  const onClickStart = (e: any) => {
    e.stopPropagation();
    setSelectType("start");
  };

  const onClickEnd = (e: any) => {
    e.stopPropagation();
    setSelectType("end");
  };

  const onCalendarOpen = () => {
    setSelectType("start");
  };

  const usersOptions = users.users.map((user) => ({
    label: user.name,
    value: user.id,
  }));

  const courtsOptions = useMemo(() => {
    return (
      courts?.data?.map((court) => ({
        label: court.name,
        value: court.id,
      })) || []
    );
  }, [courts]);

  const debouncedApplyFilters = useDebounce(setFilters, 500);

  const handleFilter = (newFilter: Partial<MovementFilter>) => {
    goToFirstPage();

    setFilters({
      ...filters,
      ...newFilter,
    });
  };

  const handleInputSearch = (newFilter: MovementFilter) => {
    goToFirstPage();

    debouncedApplyFilters({ ...newFilter });
  };

  const isEmpty = !isLoading && !movements?.length;

  if (!hasPermission)
    return (
      <PageContainer className="flex flex-col">
        <Box className="flex justify-between mb-4.5">
          <BreadcrumbNav />
        </Box>

        <PageTitle>{t("movements.movements")}</PageTitle>

        <Box className="flex justify-center items-center">
          <PageContainer.Forbidden />
        </Box>
      </PageContainer>
    );

  if (isError)
    return (
      <PageContainer className="flex flex-col">
        <Box className="flex justify-between mb-4.5">
          <BreadcrumbNav />
        </Box>

        <PageTitle>{t("movements.movements")}</PageTitle>

        <Box className="flex justify-center items-center">
          <PageContainer.Error />
        </Box>
      </PageContainer>
    );

  return (
    <PageContainer className="flex flex-col">
      <Box className="flex justify-between mb-4.5">
        <BreadcrumbNav />
      </Box>

      <PageTitle>{t("movements.movements")}</PageTitle>

      <Box className="flex mt-4 mb-2">
        <Box className="flex items-center grow shrink max-w-[855px] mr-6">
          <Input.Group size="lg" className="grow shrink">
            <Input
              placeholder={t("movements.search_movements_placeholder")}
              className="w-full rounded-full peer"
              onChange={(e) => {
                handleInputSearch({
                  ...filters,
                  ...(e.target.value && {
                    q: e.target.value,
                  }),
                  ...(!e.target.value && {
                    q: undefined,
                  }),
                });
              }}
            />
            <Input.LeftElement className="opacity-50 peer-focus-visible:opacity-100 flex justify-center items-center w-10 h-10">
              <Icon as={MdSearch} size="6" className="text-neutral-600" />
            </Input.LeftElement>
          </Input.Group>

          <Checkbox
            className="ml-6"
            checked={!!filters?.case__active?.eq}
            onChange={() => {
              handleFilter({
                case__active: !filters?.case__active?.eq
                  ? { eq: true }
                  : undefined,
              });
            }}
          >
            {t("movements.actives_only")}
          </Checkbox>
        </Box>
      </Box>

      <Box className="flex my-4 gap-2 flex-wrap" as="form">
        <DatePicker
          locale={locale}
          customInput={
            <FilterButton
              isSelected={!!startDate || !!endDate}
              className="whitespace-nowrap"
              onClear={() => {
                setStartDate(null);
                setEndDate(null);
                onChangeDate(null);

                return handleFilter({
                  ...filters,
                  official_date: undefined,
                  custom_data: {
                    ...filters.custom_data,
                    startDate: undefined,
                    endDate: undefined,
                  },
                });
              }}
            >
              {t("movements.filter_movement_date")}{" "}
              {[startDate, endDate].filter(Boolean).length
                ? `(${[startDate, endDate].filter(Boolean).length})`
                : ""}
            </FilterButton>
          }
          selected={selectType === "start" ? startDate : endDate}
          onChange={onChangeDate}
          isHeaderClickeable={false}
          selectsStart={selectType === "start"}
          selectsEnd={selectType === "end"}
          startDate={startDate}
          endDate={endDate}
          minDate={selectType === "end" ? startDate : undefined}
          shouldCloseOnSelect={false}
          onCalendarOpen={onCalendarOpen}
          openToDate={
            startDate && endDate && selectType === "start"
              ? startDate
              : endDate!
          }
          monthsShown={2}
          topContent={
            <Box className="flex m-5 mb-1 items-center space-x-5">
              <Input
                size="sm"
                value={
                  startDate ? format(startDate, t("date_formats.date")) : ""
                }
                onClick={onClickStart}
                className={`${
                  selectType === "start" && "border-[1.5px] border-primary-800"
                }`}
              />

              <Icon as={MdOutlineArrowForward} />
              <Input
                size="sm"
                onClick={onClickEnd}
                value={endDate ? format(endDate, t("date_formats.date")) : ""}
                className={`${
                  selectType === "end" && "border-[1.5px] border-primary-800"
                }`}
              />
            </Box>
          }
        />

        <Dropdown>
          <Dropdown.Button
            as={FilterButton}
            className="whitespace-nowrap"
            isSelected={!!filters.case__court_id?.or?.length}
            onClear={() => {
              handleFilter({
                case__court_id: {
                  or: [],
                },
              });
            }}
          >
            {t("cases.filter_court")}{" "}
            {!!filters.case__court_id?.or?.length &&
              `(${filters.case__court_id?.or?.length})`}
          </Dropdown.Button>
          <Dropdown.List className="py-0 w-[380px]">
            <SelectMultiCheckbox
              options={courtsOptions}
              valueAsObject={false}
              isMulti
              removeShadows
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              menuIsOpen
              isClearable={false}
              disabled={courts.isLoading || courts.isError}
              placeholder={t("ui.search_placeholder")}
              value={filters.case__court_id?.or}
              onChange={(values: []) => {
                handleFilter({
                  case__court_id: {
                    or: [...values],
                  },
                });
              }}
            />
          </Dropdown.List>
        </Dropdown>
        <Dropdown>
          <Dropdown.Button
            as={FilterButton}
            className="whitespace-nowrap"
            isSelected={!!filters.case__user_ids?.or?.length}
            onClear={() => {
              handleFilter({
                case__user_ids: {
                  or: [],
                },
              });
            }}
          >
            {t("cases.filter_assigned_users")}{" "}
            {!!filters.case__user_ids?.or?.length &&
              `(${filters.case__user_ids?.or?.length})`}
          </Dropdown.Button>
          <Dropdown.List className="py-0 w-[380px]">
            <SelectMultiCheckbox
              options={usersOptions}
              valueAsObject={false}
              isMulti
              removeShadows
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              menuIsOpen
              isClearable={false}
              disabled={users.isLoading || users.isError}
              placeholder={t("ui.search_placeholder")}
              value={filters.case__user_ids?.or}
              onChange={(values: []) => {
                handleFilter({
                  ...filters,
                  case__user_ids: {
                    or: [...values],
                  },
                });
              }}
            />
          </Dropdown.List>
        </Dropdown>
        {customFieldsInFilter
          ?.filter((customField) => customField.display_order_filter > 0)
          .map((customField) => {
            const customData = (filters as any)["custom_data"];
            return (
              <CustomFieldFilter
                id={customField.id}
                key={`custom_field_filter_${customField.id}`}
                name={customField.name}
                value={
                  customData[customField.name]?.eq ||
                  customData[customField.name]?.lte ||
                  customData[customField.name]?.gte ||
                  customData[customField.name]?.or
                }
                model="Movement"
                onChange={(v, operator = "eq") => {
                  if (typeof v === "undefined" || v === null) {
                    return handleFilter({
                      ...filters,
                      custom_data: {
                        ...filters.custom_data,
                        [customField.name]: undefined,
                      },
                    });
                  }
                  return handleFilter({
                    ...filters,
                    custom_data: {
                      ...filters.custom_data,
                      [customField.name]: {
                        ...customData[customField.name],
                        [operator]: v,
                      },
                    },
                  });
                }}
              />
            );
          })}
      </Box>

      <Box className="flex flex-col gap-2 mt-6">
        {!isEmpty && (
          <Text className="text-primary-900 mb-2.5 font-semibold">
            {t("ui.showing_of_results", {
              showing: movements?.length,
              total: pagination?.total,
            })}
          </Text>
        )}
        <Table.Container
          variant="elevated"
          className="rounded-md"
          isLoading={isLoading || isRefetching}
          page={pagination?.page}
          rowsPerPage={pagination?.pageSize}
          totalPages={pagination?.pageCount}
          onNextPage={nextPage}
          onPreviousPage={prevPage}
          onChangeRowsPerPage={onChangePageRows}
          totalRows={pagination?.total}
        >
          <Table>
            <Table.Head className="bg-primary-800 text-neutral-50">
              <Table.Row className="text-neutral-50">
                <Table.Header>{t("movements.title")}</Table.Header>
                <Table.Header>{t("movements.case")}</Table.Header>
                <Table.Header className="leading-tight">
                  {t("movements.movement_date")}
                </Table.Header>
                <Table.Header>{t("movements.detail")}</Table.Header>
                {customFieldsInTable.map((field) => (
                  <Table.Header key={field.id}>{field.name}</Table.Header>
                ))}
                <Table.Header>{t("movements.emitter")}</Table.Header>
              </Table.Row>
            </Table.Head>
            <TableBody isEmpty={isEmpty} title={t("ui.no_data")}>
              {movements.map((item) => (
                <Table.Row
                  key={item.id}
                  className="even:bg-neutral-100 odd:bg-neutral-50"
                >
                  <Table.Cell>{item.title}</Table.Cell>
                  <Table.Cell>
                    <RrLink
                      to={`/cases/${item.case?._id}`}
                      onClick={() => trackEvent("Case clicked")}
                    >
                      <Text className="text-sm font-bold">
                        {`${item.case?.code} - ${
                          courts.courtMap.get(item.case?.court_id as string)
                            ?.name || ""
                        }`}
                      </Text>
                      <Text className="text-sm">{item.case?.title}</Text>
                    </RrLink>
                  </Table.Cell>

                  <Table.Cell>
                    {parseOfficialDate(
                      item.official_date,
                      t("date_formats.date"),
                    )}
                  </Table.Cell>
                  <Table.Cell className="flex flex-col">
                    <Box className="flex flex-col gap-1">
                      <Box>
                        {customFieldsNotInTable
                          .filter(
                            (customField) =>
                              item.custom_data?.[customField.name],
                          )
                          .map((field, idx) => {
                            if (idx <= 2)
                              return (
                                <Box className="flex gap-2" key={field.id}>
                                  <Text className="font-semibold text-sm">
                                    {field.name}:
                                  </Text>
                                  <Text className="text-sm">
                                    {getCustomFieldValueFormatted(
                                      item.custom_data?.[field.name],
                                      field.type,
                                    )}
                                  </Text>
                                </Box>
                              );
                          })}
                      </Box>
                      <Box className="flex flex-col gap-2">
                        <Box>
                          {item.attachments?.map((attachment) => (
                            <AttachmentLink
                              key={attachment._id}
                              attachment={attachment}
                              queryKey={queryKey}
                              movementId={item.id}
                            />
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Table.Cell>
                  {customFieldsInTable.map((field) => (
                    <Table.Cell key={`movement_table_custom_field_${field.id}`}>
                      {getCustomFieldValueFormatted(
                        item.custom_data?.[field.name],
                        field.type,
                      ) || "-"}
                    </Table.Cell>
                  ))}
                  <Table.Cell>
                    <CreatorAvatar user={users.findById(item.created_by_id)} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </TableBody>
          </Table>
          <TableFooter isEmpty={isEmpty} />
        </Table.Container>
      </Box>
    </PageContainer>
  );
}
