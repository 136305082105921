import {
  Box,
  Button,
  FormControl,
  Icon,
  IconButton,
  Input,
  Table,
} from "@suit-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineDelete, MdOutlineEdit, MdSearch } from "react-icons/md";
import { TableBody } from "@/ui/TableBody";
import Confirm from "@/ui/Confirm";
import { trackEvent } from "@/utils/analytics/trackEvent";
import { useGetProjects } from "../useCases/useGetProjects";
import { stringIncludes } from "@/utils/texts/stringIncludes";
import { ProjectModal } from "./ProjectModal";
import { useCreateProject } from "../useCases/useCreateProject";
import { Project } from "../types/Project";
import { useUpdateProject } from "../useCases/useUpdateProject";
import { useDeleteProject } from "../useCases/useDeleteProject";
import { useGetClients } from "@/features/clients/useCases/useGetClients";
import ClientSelect from "@/features/cases/components/ClientSelect";

export const ProjectsTable = () => {
  const { t } = useTranslation();

  const [createProjectModal, setCreateProjectModal] = useState(false);
  const [updateProjectModal, setUpdateProjectModal] = useState<Project | null>(
    null,
  );
  const [search, setSearch] = useState("");
  const [clientSelected, setClientSelected] = useState<string | undefined>(
    undefined,
  );

  const { projects, isLoading } = useGetProjects();
  const { clientMap, isLoading: isClientsLoading } = useGetClients();
  const { deleteProject, isPending: isDeleting } = useDeleteProject();
  const { updateProject, isPending: isUpdating } = useUpdateProject();
  const { createProject, isPending: isCreating } = useCreateProject();

  const handleUpdateProject = async (data: Partial<Project>) => {
    if (!updateProjectModal || !updateProjectModal.code) return;

    await updateProject({
      id: updateProjectModal.id,
      data: {
        ...updateProjectModal,
        ...data,
      },
    });
    setUpdateProjectModal(null);
    trackEvent("Project updated");
  };

  const handleCreateProject = async (data: Partial<Project>) => {
    await createProject(data);
    setCreateProjectModal(false);
    trackEvent("Project created");
  };

  const filteredProjects = projects.filter(({ name, code, client_id }) => {
    if (clientSelected) {
      return (
        client_id === clientSelected &&
        (stringIncludes(name, search) || stringIncludes(code ?? "", search))
      );
    }

    return stringIncludes(name, search) || stringIncludes(code ?? "", search);
  });

  const isEmpty = !isLoading && filteredProjects.length <= 0;

  console.log({ clientSelected });

  return (
    <Box className="flex flex-col gap-4 mt-4">
      <Box className="flex justify-between items-center gap-4 relative">
        <Input.Group size="lg" className="grow shrink max-w-4xl">
          <Input
            placeholder={t("projects.search_projects_placeholder")}
            className="w-full rounded-full peer"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Input.LeftElement className="opacity-50 peer-focus-visible:opacity-100 flex justify-center items-center w-10 h-10">
            <Icon as={MdSearch} size="6" className="text-neutral-600" />
          </Input.LeftElement>
        </Input.Group>

        <FormControl className="min-w-96">
          <FormControl.Label className="absolute bottom-12">
            {t("projects.client")}
          </FormControl.Label>

          <ClientSelect
            onChange={(value) => setClientSelected(value)}
            className=""
            size="lg"
          />
        </FormControl>

        <Button className="ml-auto" onClick={() => setCreateProjectModal(true)}>
          {t("projects.create")}
        </Button>
      </Box>

      <Table.Container className="rounded-md" variant="elevated">
        <Table>
          <Table.Head className="bg-primary-800 text-neutral-50">
            <Table.Row className="text-neutral-50">
              <Table.Header>{t("projects.code")}</Table.Header>
              <Table.Header>{t("projects.client")}</Table.Header>
              <Table.Header>{t("projects.name")}</Table.Header>
              <Table.Header>{t("ui.options")}</Table.Header>
            </Table.Row>
          </Table.Head>
          <TableBody
            isEmpty={isEmpty}
            title={t("ui.empty_data")}
            className="bg-neutral-50"
          >
            {filteredProjects.map((item) => {
              return (
                <Table.Row
                  key={item.id}
                  className={"even:bg-neutral-100 odd:bg-neutral-50"}
                >
                  <Table.Cell>{item.code}</Table.Cell>
                  <Table.Cell>
                    {isClientsLoading
                      ? t("ui.loading")
                      : clientMap.get(item.client_id)?.name}
                  </Table.Cell>
                  <Table.Cell>{item.name}</Table.Cell>
                  <Table.Cell className="flex justify-center">
                    <IconButton
                      icon={<MdOutlineEdit />}
                      variant="text"
                      color="neutral"
                      className="text-neutral-600"
                      onClick={() => setUpdateProjectModal(item)}
                    />

                    <Confirm
                      title={`${t("ui.delete")} ${item.name}`}
                      onConfirm={async () => {
                        await deleteProject(item.id);
                        trackEvent("Project deleted");
                      }}
                      onConfirmText={t("ui.delete")}
                      button={
                        <IconButton
                          icon={<MdOutlineDelete />}
                          variant="text"
                          color="neutral"
                          className="text-neutral-600"
                          onClick={() => deleteProject(item.id)}
                        />
                      }
                      body={t("projects.confirm_delete_description")}
                      isLoading={isDeleting}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </TableBody>
        </Table>
      </Table.Container>

      <ProjectModal
        isOpen={createProjectModal}
        onClose={() => setCreateProjectModal(false)}
        isLoading={isCreating}
        mode="create"
        onSubmit={handleCreateProject}
      />

      <ProjectModal
        isOpen={!!updateProjectModal}
        onClose={() => setUpdateProjectModal(null)}
        isLoading={isUpdating}
        mode="update"
        defaultValues={updateProjectModal}
        onSubmit={handleUpdateProject}
      />
    </Box>
  );
};

