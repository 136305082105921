import { TableFooter } from "@/ui/TableFooter";
import { Box, Table } from "@suit-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { CaseWithConnectionForm } from "../../types/CaseWithConnectionForm";
import { usePollSearchResult } from "../../useCases/usePollSearchResult";
import { useSearch } from "../../useCases/useSearch";
import { CaseRow } from "./CaseRow";
import { useTranslation } from 'react-i18next';

interface SearchResultStepProps {
  onStepComplete?: () => void;
  skipConfirmation?: boolean;
  mode: "creating" | "connecting";
}
const DEFAULT_ITEMS_PER_PAGE = 10;

export const CasesToConnectTable = ({
  onStepComplete,
  skipConfirmation = false,
  mode,
}: SearchResultStepProps) => {
  const methods = useFormContext<CaseWithConnectionForm>();
  const { credential_id, searchKey, finder, search_params } = methods.watch();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const search = useSearch();

  const { data: searchResultDataPolling } = usePollSearchResult(
    search.data?.key ?? searchKey,
  );

  const handleNextPage = async ({ nextPage }: { nextPage?: number | null }) => {
    if (!nextPage) return;

    const { paginable: isPaginable } = searchResultDataPolling || {};

    if (isPaginable)
      await search.mutateAsync({
        finder,
        search_params,
        credential_id,
        page: nextPage,
      });

    setPage(nextPage);
  };

  const handlePreviousPage = async () => {
    if (page <= 1) return;

    const { paginable: isPaginable } = searchResultDataPolling || {};

    if (isPaginable)
      await search.mutateAsync({
        finder,
        search_params,
        credential_id,
        page: page - 1,
      });

    setPage(page - 1);
  };

  if (!searchResultDataPolling) return null;

  const { paginable, rows, pagination, col_names } = searchResultDataPolling;

  const items = paginable
    ? rows
    : rows?.slice(
        (page - 1) * DEFAULT_ITEMS_PER_PAGE,
        page * DEFAULT_ITEMS_PER_PAGE,
      );
  const itemsPerPage = paginable
    ? pagination.page_size
    : DEFAULT_ITEMS_PER_PAGE;

  const totalPages = paginable
    ? pagination?.total_pages
    : Math.ceil(rows?.length / DEFAULT_ITEMS_PER_PAGE);

  const totalRows = paginable ? pagination?.total_entries : rows?.length;

  const onSuccessfullyConnect = () => {
    onStepComplete?.();
  };

  return (
    <Box as="form">
      <Table.Container
        className="rounded-md"
        page={page}
        rowsPerPage={itemsPerPage}
        totalPages={totalPages}
        totalRows={totalRows}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
        variant="elevated"
      >
        <Table>
          <Table.Head className="bg-primary-800 text-neutral-50">
            <Table.Row className="text-neutral-50">
              {col_names?.map((colName, colIndex) => (
                <Table.Header
                  key={`searchResultHeader-${colName}-${colIndex}`}
                  className="py-2"
                >
                  {colName}
                </Table.Header>
              ))}
              <Table.Header>{t("ui.actions")}</Table.Header>
            </Table.Row>
          </Table.Head>

          {items?.map(({ values, scraper_params }, index) => {
            const id = values.join("-");

            return (
              <CaseRow
                key={`SearchResultItem-${index}-${id}`}
                values={values}
                scraper_params={scraper_params}
                credential_id={credential_id}
                onSuccessfullyConnect={onSuccessfullyConnect}
                skipConfirmation={skipConfirmation}
                mode={mode}
              />
            );
          })}
        </Table>
        <TableFooter isEmpty={!rows?.length} rowsPerPageSelect={false} />
      </Table.Container>
    </Box>
  );
};

