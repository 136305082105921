import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";
import { ScraperParams } from "../types/ScraperParams";
import { useTranslation } from "react-i18next";
import { useToast } from "@suit-ui/react";

interface ConnectCaseParams {
  scraper_params: ScraperParams;
  credential_id: string | undefined;
}

const connectCase = async ({ scraper_params }: ConnectCaseParams) => {
  const response = await fetcher.get<{ key: string }>("/scrapers/scrape", {
    params: { ...scraper_params },
  });
  return response.data;
};

export function useConnectCase(scrapperParams: ScraperParams) {
  const toast = useToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: connectCase,
    mutationKey: ["connectCase", scrapperParams],
    onError: () => {
      toast.add({
        content: t("cases.failed_to_scrape_data_key"),
        status: "danger",
      });
    },
  });
}
