// Based on Waluigi's TextWithOverflow component
import { Box, Tooltip } from "@suit-ui/react";
import { useDebounce } from "@/utils/debounce/useDebounce";
import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

interface TextWithOverflowProps {
  children: ReactNode;
}

/** Shows ellipsis and a tooltip when text is overflowed */
export const TextWithOverflow: React.FC<TextWithOverflowProps> = ({ children }) => {
  const refText = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCloseTooltip = useCallback(
    useDebounce(() => setShowTooltip(false), 300),
    [showTooltip],
  );

  const checkOverflow = () => {
    if (!refText.current) {
      setIsOverflowed(false);
      return;
    }

    setIsOverflowed(
      refText.current?.offsetWidth < refText.current?.scrollWidth,
    );
  }

  useLayoutEffect(() => {
    checkOverflow();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', checkOverflow);
    return () => {
      // clean the event listener when the component is unmounted
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <Box
      className="overflow-x-hidden"
      onMouseEnter={() => {
        if (!isOverflowed) return;

        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        debouncedCloseTooltip();
      }}
    >
      <Tooltip
        label={children}
        isOpen={showTooltip}
        disabled={!isOverflowed}
        shouldWrapChildren
      >
        <Box className="truncate" ref={refText}>
          {children}
        </Box>
      </Tooltip>
    </Box>
  );
};
