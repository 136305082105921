import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Project } from "../types/Project";

export const GET_PROJECTS_FETCH_KEY = "get-projects";

const getProjects = async (): Promise<Project[]> => {
  const response = await fetcher(`/projects`);
  return response.data;
};

export const useGetProjects = () => {
  const query = useQuery({
    queryKey: [GET_PROJECTS_FETCH_KEY],
    queryFn: getProjects,
  });

  const projectMap = useMemo(() => {
    const projects = query.data || [];

    return new Map<string, Project>(projects.map((p) => [p.id, p]));
  }, [query.data]);

  return {
    ...query,
    projects: query.data ?? [],
    isPending: query.isPending,
    projectMap,
  };
};

