import { keepPreviousData, useQuery } from "@tanstack/react-query";
import qs from "qs";
import { Case } from "../types/Case";
import { fetcher } from "@/utils/fetchers/fetcher";
import { PaginatedResource } from "@/utils/pagination/PaginatedResource";
import { usePagination } from "@/utils/pagination/usePagination";
import { FilterOperators } from "@/utils/filter";

export const GET_CASES_FETCH_KEY = "get_cases";

export type CaseFilter = {
  active?: {
    [key in FilterOperators]?: boolean;
  };
  court_id?: {
    [key in FilterOperators]?: string[];
  };
  client_id?: {
    [key in FilterOperators]?: string[];
  };
  project_id?: {
    [key in FilterOperators]?: string[];
  };
  user_ids?: {
    [key in FilterOperators]?: string[];
  };
  id?: {
    [key in FilterOperators]?: string[];
  };
  last_movement_date?: {
    [key in FilterOperators]?: Date | string;
  };
  title?: {
    [key in FilterOperators]?: string;
  };
  code?: {
    [key in FilterOperators]?: string;
  };
  sort?: string[];

  custom_data?: any;
  q?: string;
};

/** Parameters that are not used to filter cases */
export type CaseExtraParams = {
  showConnectModal?: string
}

const getCases = async ({
  page,
  pageSize,
  filter,
}: {
  page: number;
  pageSize: number;
  filter?: CaseFilter;
}) => {
  const response = await fetcher<PaginatedResource<Case[]>>(`/cases`, {
    params: {
      filter,
      pagination: {
        page,
        pageSize,
      },
    },
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: "brackets" }),
  });
  return response.data;
};

interface UseGetCasesParams {
  filters?: CaseFilter;
  pagination?: {
    defaultValues?: {
      page?: number;
      pageSize?: number;
    };
  };
}

export const useGetCases = ({
  filters,
  pagination,
}: UseGetCasesParams = {}) => {
  const {
    page,
    pageSize,
    nextPage,
    prevPage,
    onChangePageRows,
    goToFirstPage,
  } = usePagination(pagination?.defaultValues);

  const query = useQuery({
    queryKey: [GET_CASES_FETCH_KEY, page, pageSize, filters],
    queryFn: () => getCases({ page, pageSize, filter: filters }),
    placeholderData: keepPreviousData,
  });

  return {
    ...query,
    cases: query.data?.data || [],
    pagination: query.data?.meta.pagination,
    nextPage,
    prevPage,
    onChangePageRows,
    goToFirstPage,
  };
};
