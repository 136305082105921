import { Box, Banner, FormControl, Button, Select } from "@suit-ui/react";
import DynamicForm from "../DynamicForm";
import { useTranslation } from "react-i18next";
import { useGetCaseFinders } from "../../useCases/useGetCaseFinders";
import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useValidateSearch } from "../../useCases/useValidateSearch";
import { CaseFinder } from "../../types/CaseFinder";
import { CaseSearch } from "../../types/CaseSearch";
import { usePollSearchResult } from "../../useCases/usePollSearchResult";
import { useSearch } from "../../useCases/useSearch";
import { CaseWithConnectionForm } from "../../types/CaseWithConnectionForm";
import { CredentialsSelect } from "../CredentialsSelect";
import { trackEvent } from "@/utils/analytics/trackEvent";

interface SearchCaseFormProps {
  cancelButtonLabel?: string;
  onStepComplete?: () => void;
  onCancel: () => void;
}

export const SearchCaseForm = ({
  cancelButtonLabel,
  onStepComplete,
  onCancel,
}: SearchCaseFormProps) => {
  const { t } = useTranslation();
  const [isFirstTimeLocalStorageState, setIsFirstTimeLocalStorageState] =
    useState(true);
  const { data: caseFinders, isLoading } = useGetCaseFinders();
  const methods = useFormContext<CaseWithConnectionForm>();
  const { watch, setValue, getValues } = methods;
  const validateSearch = useValidateSearch();
  const search = useSearch();
  const scrollRef = useRef<HTMLDivElement>(null);

  const finderId = watch("finderId");

  const {
    data: searchResultDataPolling,
    isLoading: isPollingSearchResult,
    invalidatePollSearchResult,
  } = usePollSearchResult(search?.data?.key);
  const [customDataInput, setCustomDataInput] = useState<{
    params: CaseSearch["params"];
    selectors: CaseSearch["selectors"];
    events: CaseSearch["events"];
  }>({ params: {}, selectors: {}, events: {} });

  const finderOptions = useMemo(
    () =>
      caseFinders?.map((finder) => ({
        label: finder.name,
        value: finder.id,
      })),
    [caseFinders],
  );

  const getFinderById = useCallback(
    (id: string) => caseFinders?.find((finder) => finder.id === id),
    [caseFinders],
  );

  const currentFinder = useMemo(
    () => (finderId ? (getFinderById(finderId) as CaseFinder) : null),
    [finderId, getFinderById],
  );

  const searchParamsParsed = (data: CaseWithConnectionForm) => {
    const search_params: CaseSearch["params"] = {};
    for (const key in data.search_params) {
      search_params[`search_params[${key}]`] = data.search_params[key];
    }

    return search_params;
  };

  const onSubmit = methods.handleSubmit(async (data) => {
    const search_params = searchParamsParsed(data);
    const responseValidate = await validateSearch.mutateAsync({
      finder: currentFinder!.code,
      search_params,
      credential_id: data.credential_id,
    });
    invalidatePollSearchResult();

    methods.reset({
      ...methods.getValues(),
      search_params: responseValidate.params,
    });

    trackEvent("Search case form submitted");

    if (responseValidate.errors.length) {
      invalidatePollSearchResult();
      scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      search.mutateAsync({
        finder: currentFinder!.code,
        search_params,
        credential_id: data.credential_id,
      });
    }
  });

  useEffect(() => {
    if (!searchResultDataPolling) {
      return;
    }

    const { rows, col_names, pagination, paginable, detail_cols } =
      searchResultDataPolling;
    if (!rows || rows.length === 0) {
      return;
    }

    methods.setValue("result_params", {
      rows,
      col_names,
      pagination,
      paginable,
      detail_cols,
    });

    methods.setValue("finder", currentFinder!.code);
    methods.setValue("credential_id", methods.getValues().credential_id);
    methods.setValue("search_params", searchParamsParsed(methods.getValues()));
    if (search?.data?.key) methods.setValue("searchKey", search.data.key);

    onStepComplete?.();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultDataPolling]);

  useEffect(() => {
    if (!currentFinder) {
      return;
    }

    const { params, selectors, events } = currentFinder.empty_search;

    const storedParams = getValues("search_params");

    let convertedParams: { [key: string]: string } | undefined = undefined;
    if (isFirstTimeLocalStorageState && !!getValues("finder") && storedParams) {
      convertedParams = Object.entries(storedParams).reduce(
        (acc: { [key: string]: string }, [key, value]) => {
          const newKey = key.match(/\[(.*?)\]/)?.[1] || key; // Extrae el texto dentro de los corchetes
          acc[newKey] = value;
          return acc;
        },
        {},
      );
      setIsFirstTimeLocalStorageState(false);
    }

    setCustomDataInput({
      params: convertedParams || params,
      selectors,
      events,
    });

    methods.reset({
      search_params: convertedParams || params,
      finderId: currentFinder.id,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFinder]);

  const submitIsLoading = useMemo(
    () => validateSearch.isPending || search.isPending || isPollingSearchResult,
    [validateSearch, search, isPollingSearchResult],
  );

  const bannerClass = "mb-6";

  return (
    <>
      <Box className="flex justify-center w-full">
        <Box
          as="form"
          className="flex flex-col gap-6 w-full"
          onSubmit={onSubmit}
        >
          <Box className="h-[400px] overflow-y-auto" ref={scrollRef}>
            {!!validateSearch.data &&
              validateSearch.data?.errors?.map((error) => (
                <Banner status="danger" key={error} className={bannerClass}>
                  <span dangerouslySetInnerHTML={{ __html: error }} />
                </Banner>
              ))}

            {!validateSearch.data &&
              !validateSearch.isPending &&
              currentFinder?.empty_search?.errors?.map((error) => (
                <Banner key={error} className={bannerClass}>
                  <span dangerouslySetInnerHTML={{ __html: error }} />
                </Banner>
              ))}

            {searchResultDataPolling &&
              searchResultDataPolling?.rows?.length === 0 && (
                <Banner className={bannerClass}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("cases.search_case_no_results"),
                    }}
                  />
                </Banner>
              )}

            {searchResultDataPolling?.errors?.map((error) => (
              <Banner status="danger" className={bannerClass}>
                <span dangerouslySetInnerHTML={{ __html: error }} />
              </Banner>
            ))}

            <Box className="flex flex-col gap-6">
              <FormControl required>
                <FormControl.Label>
                  {t("cases.search_finder_label")}
                </FormControl.Label>

                <Select
                  isLoading={isLoading}
                  options={finderOptions}
                  valueAsObject={false}
                  value={finderId}
                  onChange={(finderId: string) => {
                    // Unregister prev search fields to avoid include it on the validateSearch payload.
                    Object.keys(currentFinder?.empty_search.params || {}).forEach(
                      (field) => {
                        methods.unregister(`search_params[${field}]` as any);
                      },
                    );

                    setValue("finderId", finderId);
                  }}
                  menuPosition="fixed"
                />
              </FormControl>

              {currentFinder?.credential_type && (
                <FormControl>
                  <FormControl.Label>{t("cases.credentials")}</FormControl.Label>

                  <Controller
                    control={methods.control}
                    name="credential_id"
                    render={({ field: { onChange, value } }) => (
                      <CredentialsSelect
                        value={value}
                        onChange={onChange}
                        credentialTypeFilter={currentFinder?.credential_type}
                      />
                    )}
                  />
                </FormControl>
              )}

              {currentFinder && (
                <DynamicForm
                  id={currentFinder.id}
                  params={customDataInput?.params}
                  selectors={customDataInput?.selectors}
                  events={customDataInput?.events}
                  onValidateDependentFields={async () => {
                    const data = methods.getValues();
                    const search_params = searchParamsParsed(data);
                    const responseValidate = await validateSearch.mutateAsync({
                      finder: currentFinder.code,
                      search_params,
                    });

                    const { params, selectors, events } = responseValidate;
                    methods.reset({
                      ...data,
                      search_params: params,
                    });
                    setCustomDataInput({ params, selectors, events });
                  }}
                />
              )}
            </Box>
          </Box>

          <Box className="flex mt-4">
            <Button
              variant="text"
              className="mr-auto"
              onClick={onCancel}
            >
              {cancelButtonLabel || t("ui.cancel")}
            </Button>

            <Button
              type="submit"
              loading={submitIsLoading}
              disabled={!currentFinder}
            >
              {t("cases.search_case")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
