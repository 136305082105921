import { useTenantData } from "@/features/session/useCases/useTenantData";
import { routes } from "@/router";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CaseWithConnectionForm } from "../../types/CaseWithConnectionForm";
import { useCreateCase } from "../../useCases/useCreateCase";
import { CaseForm, CaseFormData } from "../CaseForm";

interface CaseDataStepProps {
  onStepComplete?: () => void;
  onSaveFailed: (error: string | null) => void;
}

const CaseDataStep = ({ onStepComplete, onSaveFailed }: CaseDataStepProps) => {
  const { mutateAsync, isPending } = useCreateCase();
  const { watch } = useFormContext<CaseWithConnectionForm>();
  const navigate = useNavigate();
  const ccase = watch("case_data");
  const { getFFValue } = useTenantData();
  const FFEnableConnectCases = getFFValue("enable_connect_cases");

  if (!ccase) {
    return;
  }
  const kase = {
    title: ccase.title,
    code: ccase.code,
    court_id: ccase.court_id,
    user_ids: ccase.user_ids,
    custom_data: ccase.custom_data,
    credential_id: ccase.credential_id,
  };

  const handleSave = async (formCase: CaseFormData) => {
    const { id } = await mutateAsync({
      ...ccase,
      ...formCase,
      scraper_params: ccase?.scraper_params,
      key: ccase?.key,
    });
    onStepComplete?.();
    if (FFEnableConnectCases) {
      localStorage.removeItem("connectCaseFormResults");
    }
    navigate(`${routes.cases}/${id}`);
  };

  return (
    <>
      <CaseForm
        onSave={handleSave}
        isLoading={isPending}
        ccase={kase}
        readonlyFields={ccase.readonly_fields}
        onSaveFailed={onSaveFailed}
      />
    </>
  );
};

export { CaseDataStep };
