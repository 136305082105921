import { routes } from "@/router";
import { trackEvent } from "@/utils/analytics/trackEvent";
import {
  Box,
  Button,
  Icon,
  Modal,
  Text,
  useDisclosure,
  Link,
} from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import {
  MdOutlineAdd,
  MdOutlineOpenInNew,
  MdPublic,
  MdPublicOff,
} from "react-icons/md";
import { Link as RrLink } from "react-router-dom";

export const OldCaseModal: React.FC = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <Button
        leftIcon={<MdOutlineAdd />}
        onClick={() => {
          trackEvent("Add case modal opened");
          onOpen();
        }}
        size="lg"
      >
        {t("cases.add_case")}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="outside"
        size="md"
      >
        <Modal.Overlay />

        <Modal.Content>
          <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
            {t("cases.new_case")}
          </Modal.Header>

          <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
          <Modal.Body>
            <Text className="text-md font-semibold">
              {t("cases.new_case_description")}
            </Text>

            <Box className="flex justify-center mt-11 gap-6">
              <Box
                as={RrLink}
                to={routes.caseWithConnection}
                onClick={() => {
                  trackEvent(
                    "Add case modal: Connected to official site clicked",
                  );
                }}
                className="shadow-md rounded-md flex flex-col items-center p-5 text-sm hover:(bg-primary-100 text-primary-700) active:text-primary-800"
              >
                <Icon as={MdPublic} className="mb-2" size="12" />
                {t("cases.connected_to_official_site")}
              </Box>
              <Box
                as={RrLink}
                to={routes.caseWithoutConnection}
                onClick={() =>
                  trackEvent(
                    "Add case modal: Not connected to official site clicked",
                  )
                }
                className="shadow-md rounded-md flex flex-col items-center p-5 text-sm hover:(bg-primary-100 text-primary-700) active:text-primary-800"
              >
                <Icon as={MdPublicOff} className="mb-2" size="12" />
                {t("cases.not_connected_to_official_site")}
              </Box>
            </Box>
          </Modal.Body>

          <Modal.Footer className="justify-between items-center">
            <Link
              href="https://academy.lemontech.com/hc/es/sections/19281672055195-Conexiones"
              isExternal
              disableVisitedStyle
              onClick={() =>
                trackEvent(
                  "Add case modal: View connections by country clicked",
                )
              }
            >
              <Icon as={MdOutlineOpenInNew} className="mr-2" size="5" />
              {t("cases.view_connection_by_country")}
            </Link>
            <Button
              onClick={() => {
                trackEvent("Add case modal closed");
                onClose();
              }}
            >
              {t("ui.cancel")}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};
