import {
  Box,
  Button,
  FormControl,
  Icon,
  Input,
  Modal,
  Tooltip,
} from "@suit-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useRef } from "react";
import ClientSelect from "@/features/cases/components/ClientSelect";
import { MdInfo } from "react-icons/md";

const schema = z.object({
  name: z.string().min(1),
  client_id: z.string().min(1),
  code: z.string(),
});

type ProjectFormData = z.infer<typeof schema>;

export interface ProjectModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: (data: ProjectFormData) => void;
  defaultValues?: ProjectFormData | null;
  mode?: "create" | "update";
  isLoading?: boolean;
}

export const ProjectModal = ({
  isOpen,
  onClose,
  onSubmit = () => {},
  defaultValues,
  mode,
  isLoading,
}: ProjectModalProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      client_id: "",
      code: "",
      ...defaultValues,
    },
  });
  const prevDefaultValues = useRef<ProjectFormData | null>(null);

  if (defaultValues && prevDefaultValues.current !== defaultValues) {
    prevDefaultValues.current = defaultValues;
    reset(defaultValues);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      closeOnOverlayClick={false}
      onAnimationComplete={() => reset()}
    >
      <Modal.Overlay />

      <Modal.Content as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
          {mode === "update" ? t("ui.edit") : t("ui.create")}{" "}
          {t("projects.project")?.toLocaleLowerCase()}
        </Modal.Header>

        <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />

        <Modal.Body className="flex flex-col gap-2">
          <FormControl required isInvalid={!!errors.name}>
            <FormControl.Label>{t("projects.name")}</FormControl.Label>
            <Input placeholder={t("projects.name")} {...register("name")} />
            <FormControl.ErrorMessage>
              {t("form_errors.field_required")}
            </FormControl.ErrorMessage>
          </FormControl>

          {mode === "update" && (
            <FormControl>
              <Box className="flex items-center">
                <FormControl.Label>{t("projects.code")}</FormControl.Label>

                <Tooltip label={t("projects.code_tooltip")} placement="right">
                  <Box className="flex">
                    <Icon
                      as={MdInfo}
                      size="4"
                      className="text-neutral-400 ml-2"
                    />
                  </Box>
                </Tooltip>
              </Box>
              <Input {...register("code")} disabled />
            </FormControl>
          )}

          <FormControl isInvalid={!!errors.client_id} required>
            <FormControl.Label>{t("cases.client")}</FormControl.Label>

            <Controller
              control={control}
              name="client_id"
              render={({ field: { onChange, onBlur, value } }) => (
                <ClientSelect
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  isClearable={false}
                />
              )}
            />

            <FormControl.ErrorMessage>
              {t("form_errors.field_required")}
            </FormControl.ErrorMessage>
          </FormControl>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="mr-2"
            variant="text"
            color="neutral"
            onClick={onClose}
            disabled={isLoading}
          >
            {t("events.cancel")}
          </Button>
          <Button type="submit" loading={isLoading}>
            {mode === "update" ? t("ui.save") : t("ui.add")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

