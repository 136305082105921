import { Select, SelectProps } from "@suit-ui/react";
import { useMemo } from "react";
import { useGetProjects } from "@/features/projects/useCases/useGetProjects";

interface ProjectSelectProps extends SelectProps {
  onChange?: (value: string) => void;
  onBlur?: () => void;
  value?: string;
  disabled?: boolean;
  client_id?: string;
}

const ProjectSelect: React.FC<ProjectSelectProps> = ({
  onChange,
  onBlur,
  value,
  disabled,
  className = "",
  client_id,
  ...rest
}) => {
  const { projects, isLoading } = useGetProjects();
  const projectOptions = useMemo(() => {
    if (client_id) {
      return projects
        ?.filter((p) => p.client_id === client_id)
        .map((p) => ({
          label: p.name,
          value: p.id,
        }));
    }

    return [];
  }, [projects, client_id]);

  return (
    <Select
      className={className}
      menuPosition="fixed"
      valueAsObject={false}
      value={value}
      options={projectOptions || []}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
      isLoading={isLoading}
      isClearable
      {...rest}
    />
  );
};

export default ProjectSelect;

