import { Button, Dialog, Table, useDisclosure, useToast } from "@suit-ui/react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CaseWithConnectionForm } from "../../types/CaseWithConnectionForm";
import { ScraperParams } from "../../types/ScraperParams";
import { useConnectCase } from "../../useCases/useConnectCase";
import { useCreateConnectionOfflineCase } from "../../useCases/useCreateConnectionOfflineCase";
import { usePollConnectCaseResult } from "../../useCases/usePollConnectCaseResult";
import { trackEvent } from '@/utils/analytics/trackEvent';

interface CaseRowProps {
  values: string[];
  scraper_params: ScraperParams;
  credential_id: string | undefined;
  onSuccessfullyConnect: () => void;
  skipConfirmation?: boolean;
  mode: "creating" | "connecting";
}

export const CaseRow = ({
  values,
  scraper_params,
  credential_id,
  onSuccessfullyConnect,
  skipConfirmation = false,
  mode,
}: CaseRowProps) => {
  const [dialogLoading, setDialogLoading] = useState(false);
  const [connectErrorMessage, setConnectErrorMessage] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenErrorDialog,
    onClose: onCloseErrorDialog,
    onOpen: onOpenErrorDialog,
  } = useDisclosure();
  const toast = useToast();
  const { t } = useTranslation();
  const methods = useFormContext<CaseWithConnectionForm>();

  /* Method connectCase, sends request to the backend to tell spody to search for the data, and
     returns the s3 key where the data will be stored */
  const {
    data: connectCaseKey,
    mutateAsync: connectCase,
    isPending: isPendingConnectCase,
  } = useConnectCase(scraper_params);

  /* This method use the key from useConnectCase and ask periodically if the data is
     already in the s3 bucket */
  const {
    data: connectCaseResultData,
    isLoading: isLoadingPollConnectCase,
    refetch: refetchPoll,
  } = usePollConnectCaseResult(connectCaseKey?.key);

  const { caseId } = useParams();

  const { mutateAsync: connectOfflineCase } = useCreateConnectionOfflineCase({
    caseId: caseId as string,
  });

  /* This method sends the data needed for the backend to make the conection */
  const sendDataForConnection = async () => {
    try {
      if (mode === "creating") {
        methods.setValue("case_data", {
          ...connectCaseResultData,
          scraper_params,
          key: connectCaseKey?.key,
          credential_id,
        });
      } else if (mode === "connecting") {
        await connectOfflineCase({
          scraperParams: scraper_params,
          spodySearchKey: connectCaseKey?.key as string,
          credentialId: credential_id,
          onError: handleConnectCaseError,
        });
      }
      setDialogLoading(false);
      onSuccessfullyConnect();
      if (mode === "connecting") {
        trackEvent("Case without connection connected");
      }
    } catch (e) {
      setDialogLoading(false);
    }
  };

  const handleConnectCaseError = (message: string) => {
    onClose();
    setConnectErrorMessage(message);
    onOpenErrorDialog();
  }

  const handleConnectCase = async () => {
    try {
      setDialogLoading(true);
      if (connectCaseResultData) {
        if (connectCaseResultData?.error) {
          const { data } = await refetchPoll();
          if (data?.error) {
            setDialogLoading(false);
            toast.add({
              content: t("ui.unexpected_error"),
              status: "danger",
            });
          }
        } else {
          sendDataForConnection();
        }
      } else {
        const scraperParams: { [key: string]: any } = {};
        for (const key in scraper_params) {
          scraperParams[`scraper_params[${key}]`] = scraper_params[key];
        }
        await connectCase({ scraper_params: scraperParams, credential_id });
      }
    } catch {
      setDialogLoading(false);
    }
  };

  useEffect(() => {
    if (connectCaseResultData && !connectCaseResultData?.error) {
      sendDataForConnection();
    }

    if (connectCaseResultData?.error) {
      setDialogLoading(false);
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectCaseResultData]);

  const onRowConnect = () => {
    skipConfirmation ? handleConnectCase() : onOpen();
  };

  return (
    <>
      <Table.Body className="odd:bg-neutral-100 even:bg-neutral-50">
        <Table.Row>
          {values.map((val, valIndex) => (
            <Table.Cell
              key={`SearchResultItem-cell-${valIndex}`}
              className="whitespace-pre-wrap p-4"
            >
              {val}
            </Table.Cell>
          ))}

          <Table.Cell>
            <Button
              className="w-full"
              variant="outline"
              onClick={onRowConnect}
              loading={isPendingConnectCase || isLoadingPollConnectCase}
              loadingText={t("cases.search_result_connecting_case")}
              color="primary"
            >
              {t("cases.search_result_connect_case")}
            </Button>
          </Table.Cell>
        </Table.Row>
      </Table.Body>

      {!skipConfirmation && (
        <Dialog
          isOpen={isOpen}
          onClose={onClose}
          status="info"
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="sm"
        >
          <Dialog.Overlay />
          <Dialog.Content>
            <Dialog.Header>
              {t("cases.connect_case_dialog.title")}
            </Dialog.Header>
            {!dialogLoading && <Dialog.CloseButton />}
            <Dialog.Body>{t("cases.connect_case_dialog.body")}</Dialog.Body>
            <Dialog.Footer>
              <Button
                onClick={onClose}
                variant="text"
                className="mr-2"
                size="sm"
                disabled={dialogLoading}
              >
                {t("ui.cancel")}
              </Button>
              <Button
                size="sm"
                onClick={handleConnectCase}
                loading={dialogLoading}
              >
                {t("ui.continue")}
              </Button>
            </Dialog.Footer>
          </Dialog.Content>
        </Dialog>
      )}

      {!skipConfirmation && (
        <Dialog
          isOpen={isOpenErrorDialog}
          onClose={onCloseErrorDialog}
          status="danger"
          closeOnOverlayClick={false}
          closeOnEsc={false}
          size="sm"
        >
          <Dialog.Overlay />
          <Dialog.Content>
            <Dialog.Header>
              {t("cases.connect_case_dialog.error_title")}
            </Dialog.Header>
            <Dialog.CloseButton />
            <Dialog.Body>{connectErrorMessage}</Dialog.Body>
            <Dialog.Footer>
              <Button
                onClick={onCloseErrorDialog}
                variant="text"
                className="mr-2"
                size="sm"
              >
                {t("ui.close")}
              </Button>
            </Dialog.Footer>
          </Dialog.Content>
        </Dialog>
      )}
    </>
  );
};

