import Confirm from "@/ui/Confirm";
import { useTranslation } from "react-i18next";
import { User } from "../types/User";
import { Box, Dropdown } from "@suit-ui/react";
import { trackEvent } from "@/utils/analytics/trackEvent";
import { useUpdateUser } from "../useCases/useUpdateUser";

interface ActivateUserConfirmModalProps {
  user: User;
  disabled?: boolean;
}

export function ActivateUserConfirmModal({
  user,
  disabled = false,
}: ActivateUserConfirmModalProps) {
  const { t } = useTranslation();
  const { updateUser } = useUpdateUser();
  const { name, id, role_id } = user;

  return (
    <Confirm
      title={t("users.activate_modal_title", {
        name: name ?? "",
      })}
      onConfirm={() => {
        updateUser({ userId: id, data: { role: role_id, name, active: true } });
        trackEvent("User activated");
      }}
      onConfirmText={t("ui.activate")}
      button={
        <Dropdown.Item
          className={`${
            disabled ? "opacity-50 cursor-not-allowed" : ""
          } text-success-600 hover:bg-success-100 active:(bg-success-200 text-success-700)`}
        >
          {t("ui.activate")}
        </Dropdown.Item>
      }
      body={<Box>{t("users.activate_modal_description")}</Box>}
      buttonDisabled={disabled}
    />
  );
}
