import { fetcher } from "@/utils/fetchers/fetcher";

import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { GET_USER_SETTINGS_FETCH_KEY } from "./useGetSettings";

export const updateSetting = async ({
  code,
  value,
}: {
  code: string;
  value: string | boolean;
}) => {
  const res = await fetcher.post(`/settings/${code}/user_value`, { value });
  return res.data;
};

export const useUpdateSetting = (showNotification = true) => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: updateSetting,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_USER_SETTINGS_FETCH_KEY],
      });

      if (showNotification) {
        toast.add({
          content: t("notifications.notification_updated"),
          status: "success",
        });
      }
    },
    onError: () => {
      if (showNotification) {
        toast.add({
          content: t("notifications.update_notification_error"),
          status: "danger",
        });
      }
    },
  });

  return {
    updateSetting: mutateAsync,
    isPending: isPending,
  };
};

