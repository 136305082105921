import { CrumbInputParams } from "@/router";
import { Breadcrumb } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { Link, useMatches } from "react-router-dom";

interface IMatches {
  id: string;
  handle: {
    crumb: (params: CrumbInputParams) => {
      link: string;
      name: string;
      usePlainName: boolean;
    };
  };
}

interface BreadcumbNavProps {
  params?: CrumbInputParams;
}

export function BreadcrumbNav({ params }: BreadcumbNavProps) {
  const { t } = useTranslation();
  const matches = useMatches() as IMatches[];

  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => ({
      id: match.id,
      ...match.handle.crumb(params || {}),
    }));

  const isCurrentPage = (crumbIndex: number) =>
    crumbs.length - 1 === crumbIndex;

  return (
    <Breadcrumb>
      {crumbs?.map((crumb, index) => (
        <Breadcrumb.Item key={crumb.id} isCurrentPage={isCurrentPage(index)}>
          <Breadcrumb.Link as={Link} to={crumb.link}>
            {crumb.usePlainName ? crumb.name : t(crumb.name)}
          </Breadcrumb.Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

