import { routes } from "@/router";
import { trackEvent } from "@/utils/analytics/trackEvent";
import {
  Box,
  Button,
  Icon,
  Modal,
  Text,
  useDisclosure,
  Link,
} from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import {
  MdOutlineAdd,
  MdOutlineOpenInNew,
  MdPublic,
  MdPublicOff,
} from "react-icons/md";
import { Link as RrLink, useNavigate, useSearchParams } from "react-router-dom";
import { ConnectCaseModal } from "./ConnectCaseModal";
import { useEffect } from 'react';

export const NewCaseModal: React.FC = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    onOpen: onOpenConnectCase,
    isOpen: isOpenConnectCase,
    onClose: onCloseConnectCase,
  } = useDisclosure(!!searchParams.get("showConnectModal"));
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if(searchParams.get("showConnectModal")) {
      searchParams.delete("showConnectModal");
      setSearchParams(searchParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleCaseFormCompletion = () => {
    navigate(routes.caseWithConnection);
  };

  return (
    <>
      <Button
        leftIcon={<MdOutlineAdd />}
        onClick={() => {
          trackEvent("Add case modal opened");
          onOpen();
        }}
        size="lg"
      >
        {t("cases.add_case")}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="outside"
        size="lg"
      >
        <Modal.Overlay />

        <Modal.Content>
          <Modal.Header>{t("cases.new_case")}</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body className="flex content-center justify-center flex-col h-[400px]">
            <Text className="text-center">
              {t("cases.new_case_description")}
            </Text>

            <Box className="flex flex-row justify-center mt-6 gap-4">
              <Button
                onClick={() => {
                  onOpenConnectCase();
                  onClose();
                  trackEvent(
                    "Add case modal: Connected to official site clicked",
                  );
                }}
              >
                <Box className="mr-2">
                  <MdPublic />
                </Box>
                {t("cases.connected_to_official_site")}
              </Button>
              <RrLink to={routes.caseWithoutConnection}>
                <Button
                  onClick={() =>
                    trackEvent(
                      "Add case modal: Not connected to official site clicked",
                    )
                  }
                  variant="outline"
                >
                  <Box className="mr-2">
                    <MdPublicOff />
                  </Box>
                  {t("cases.not_connected_to_official_site")}
                </Button>
              </RrLink>
            </Box>

            <Box className="flex flex-row justify-center mt-6">
              <Link
                href="https://academy.lemontech.com/hc/es/sections/19281672055195-Conexiones"
                isExternal
                disableVisitedStyle
                onClick={() =>
                  trackEvent(
                    "Add case modal: View connections by country clicked",
                  )
                }
              >
                <Icon as={MdOutlineOpenInNew} className="mr-2" size="5" />
                {t("cases.view_connection_by_country")}
              </Link>
            </Box>
          </Modal.Body>

          <Modal.Footer className="flex justify-start">
            <Button
              onClick={() => {
                trackEvent("Add case modal closed");
                onClose();
              }}
              variant="text"
            >
              {t("ui.cancel")}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      <ConnectCaseModal
        mode="creating"
        isOpen={isOpenConnectCase}
        closeModal={() => {
          onCloseConnectCase();
          onOpen();
        }}
        onCompletion={handleCaseFormCompletion}
      />
    </>
  );
};
