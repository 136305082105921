import { PageContainer } from "@/ui/containers/PageContainer";
import { PageTitle } from "@/ui/texts/PageTitle";
import { FormProvider, useForm } from "react-hook-form";
import { useLocalStorage } from "usehooks-ts";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Box, Button } from "@suit-ui/react";
import { MdArrowBackIos } from "react-icons/md";
import { CasesToConnectTable } from "../components/CasesToConnectTable";
import { CaseWithConnectionForm } from "../types/CaseWithConnectionForm";
import { useTranslation } from "react-i18next";
import { routes } from '../../../router';
import { useTenantData } from '../../session/useCases/useTenantData';
import { NoCaseResultsPlaceholder } from '../components/NoCaseResultsPlaceholder';

export const ConnectCasePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { caseId: id } = useParams();
  const { getFFValue } = useTenantData();
  const FFEnableConnectCases = getFFValue("enable_connect_cases");
  const [connectCaseFormResults] = useLocalStorage<
    CaseWithConnectionForm | undefined
  >("connectCaseFormResults", undefined);

  const methods = useForm<CaseWithConnectionForm>({
    values: connectCaseFormResults,
  });

  const onCaseConected = () => {
    localStorage.removeItem("connectCaseFormResults");
    navigate(`${location.pathname}`.replace("/connect", ""));
  };

  if (FFEnableConnectCases && !connectCaseFormResults) {
    return <NoCaseResultsPlaceholder
            title={t("cases.header.connect_case")}
            description={t("cases.connect_search_not_found_description_case_detail")}
            onClick={() => navigate(`${routes.cases}/${id}`)}
            buttonLabel={t("cases.return_to_case_detail")} />
  }

  return (
    <FormProvider {...methods}>
      <PageContainer>

        <Button
          variant="text"
          className="mr-auto mb-6 min-h-10"
          size="md"
          leftIcon={<MdArrowBackIos />}
          onClick={() => navigate(`${routes.cases}/${id}?showConnectModal=true`)}
        >
          {t("ui.back")}
        </Button>

        <PageTitle className="mt-2">{t("cases.header.connect_case")}</PageTitle>

        <Box className="mt-4">
          <CasesToConnectTable
            onStepComplete={onCaseConected}
            mode="connecting"
          />
        </Box>
      </PageContainer>
    </FormProvider>
  );
};

