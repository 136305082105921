import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Project } from "../types/Project";
import { GET_PROJECTS_FETCH_KEY } from "./useGetProjects";

const updateProject = async (
  id: Project["id"],
  data: Partial<Omit<Project, "id">>,
) => {
  const response = await fetcher.put<Project>(`/projects/${id}`, {
    project: data,
  });
  return response.data;
};

export const useUpdateProject = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (opts: {
      id: Project["id"];
      data: Partial<Omit<Project, "id">>;
    }) => updateProject(opts.id, opts.data),
    onSuccess: async () => {
      // FIXME - for some reason when data._id is used, the query GET_CLIENTS_FETCH_KEY (useGetClients) is not invalidated
      /*       await queryClient.invalidateQueries({
        queryKey: [GET_CLIENTS_FETCH_KEY, data._id],
      }); */
      await queryClient.invalidateQueries({
        queryKey: [GET_PROJECTS_FETCH_KEY],
      });
      toast.add({
        content: t("projects.project_updated_successfully"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });

  return { updateProject: mutateAsync, isPending };
};

