import { hotjar } from "react-hotjar";
import { useTenantData } from "@/features/session/useCases/useTenantData";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";

export const useHotjar = () => {
  const { users } = useGetUsers();
  const { data: user } = useCurrentUser();
  const { data: tenantData } = useTenantData();

  const initHotjar = async () => {
    const hotjarId = 5335481;
    const hotjarVersion = 6;

    if (user && tenantData && users) {
      hotjar.initialize({
        id: hotjarId,
        sv: hotjarVersion,
      });

      if (hotjar.initialized()) {
        const { id, email, role, created_at: createdAt } = user;

        const country = tenantData.country_code || "";
        const tenant = tenantData.name;
        const userId = `${tenant}-${id}`;

        hotjar.identify(userId, {
          tenant,
          country,
          role,
          userId,
          language: tenantData.locale.slice(0, 2),
          planName: tenantData.plan.cases_created,
          createdAt,
          email,
          size: users.filter(({ active }) => active)?.length || 0,
        });
      }
    }
  };

  return {
    initHotjar,
  };
};

