import {
  Input,
  DatePicker,
  FilterButton,
  Dropdown,
  SelectMultiCheckbox,
  Box,
  Icon,
} from "@suit-ui/react";
import {
  CustomFieldModel,
  useGetCustomFields,
} from "@/features/customFields/useCases/useGetCustomFields";
import { CustomFieldValueType } from "@/features/customFields/types/CustomField";
import { endOfDay, format, startOfDay } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineArrowForward } from "react-icons/md";
import { SelectOptionsWrapper } from "./SelectOptionsWrapper";
import { useDateLocale } from "@/utils/i18n/useDateLocale";

export interface CustomFieldFilterProps {
  /** Name of the custom field */
  name: string;
  id: string;
  model: CustomFieldModel;
  value?: CustomFieldValueType;
  defaultValue?: string;
  onChange?: (v: CustomFieldValueType, operator?: string) => void;
}

export const CustomFieldFilter = ({
  name,
  id,
  value,
  defaultValue,
  model,
  onChange = () => {},
}: CustomFieldFilterProps) => {
  const locale = useDateLocale();
  const { customFields } = useGetCustomFields(model);
  const { t } = useTranslation();
  const type = customFields.find((field) => field.name === name)?.type;

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const onChangeDate = (date: Date | null) => {
    if (selectType === "start") {
      setSelectType("end");
      setStartDate(date ? startOfDay(date) : null);
      if (!date) return onChange(null);
      if (type === "DATETIME") {
        onChange(date!.toISOString(), "gte");
      } else {
        onChange(format(date, "yyyy-MM-dd"), "gte");
      }
    }

    if (selectType === "end") {
      setEndDate(date ? endOfDay(date) : null);

      if (type === "DATETIME") {
        onChange(date!.toISOString(), "lte");
      } else {
        onChange(date && format(date, "yyyy-MM-dd"), "lte");
      }
    }
  };

  const [selectType, setSelectType] = useState("start");

  const onClickStart = (e: any) => {
    e.stopPropagation();
    setSelectType("start");
  };

  const onClickEnd = (e: any) => {
    e.stopPropagation();
    setSelectType("end");
  };

  const onCalendarOpen = () => {
    setSelectType("start");
  };

  if (["FLOAT", "INTEGER", "CURRENCY"].includes(`${type}`)) {
    return (
      <Input
        className="w-min"
        onChange={
          type === "INTEGER"
            ? (e) => onChange(parseInt(e.target.value))
            : (e) => onChange(parseFloat(e.target.value))
        }
        placeholder={name}
        value={String(value ?? "")}
        type="number"
        defaultValue={defaultValue}
      />
    );
  }

  if (type === "BOOL") {
    return (
      <>
        <Dropdown>
          <Dropdown.Button
            as={FilterButton}
            className="whitespace-nowrap"
            isSelected={!!(value as [])?.length}
            onClear={() => {
              onChange([]);
            }}
          >
            {name}{" "}
            {!!(value as [])?.length && ` (${value === "true" ? "Sí" : "No"})`}
          </Dropdown.Button>
          <Dropdown.List className="py-0 w-[380px]">
            <SelectMultiCheckbox
              options={[
                {
                  label: "Sí",
                  value: "true",
                },
                {
                  label: "No",
                  value: "false",
                },
              ]}
              valueAsObject={false}
              isMulti
              removeShadows
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              menuIsOpen
              isClearable={false}
              placeholder={t("ui.search_placeholder")}
              value={value}
              onChange={(values: []) => {
                const lastSelectedValue = values.at(-1);
                onChange(lastSelectedValue);
              }}
            />
          </Dropdown.List>
        </Dropdown>
      </>
    );
  }

  if (["DATE", "DATETIME"].includes(`${type}`)) {
    return (
      <Box className="w-auto">
        <DatePicker
          locale={locale}
          customInput={
            <FilterButton
              isSelected={!!startDate || !!endDate}
              className="whitespace-nowrap"
              onClear={() => {
                setStartDate(null);
                setEndDate(null);
                onChangeDate(null);
              }}
            >
              {name}{" "}
              {[startDate, endDate].filter(Boolean).length
                ? `(${[startDate, endDate].filter(Boolean).length})`
                : ""}
            </FilterButton>
          }
          selected={selectType === "start" ? startDate : endDate}
          onChange={onChangeDate}
          isHeaderClickeable={false}
          selectsStart={selectType === "start"}
          selectsEnd={selectType === "end"}
          startDate={startDate}
          endDate={endDate}
          minDate={selectType === "end" ? startDate : undefined}
          shouldCloseOnSelect={false}
          onCalendarOpen={onCalendarOpen}
          openToDate={
            startDate && endDate && selectType === "start"
              ? startDate
              : endDate!
          }
          monthsShown={2}
          topContent={
            <Box className="flex m-5 mb-1 items-center space-x-5">
              <Input
                size="sm"
                value={
                  startDate ? format(startDate, t("date_formats.date")) : ""
                }
                onClick={onClickStart}
                className={`${
                  selectType === "start" && "border-[1.5px] border-primary-800"
                }`}
              />

              <Icon as={MdOutlineArrowForward} />
              <Input
                size="sm"
                onClick={onClickEnd}
                value={endDate ? format(endDate, t("date_formats.date")) : ""}
                className={`${
                  selectType === "end" && "border-[1.5px] border-primary-800"
                }`}
              />
            </Box>
          }
        />
      </Box>
    );
  }

  if (type === "FIXED_SELECT") {
    return (
      <>
        <Dropdown>
          <Dropdown.Button
            as={FilterButton}
            className="whitespace-nowrap"
            isSelected={!!(value as [])?.length}
            onClear={() => {
              onChange([], "or");
            }}
          >
            {name} {!!(value as [])?.length && ` (${(value as [])?.length})`}
          </Dropdown.Button>
          <Dropdown.List className="py-0 w-[380px]">
            <SelectOptionsWrapper fieldId={id}>
              <SelectMultiCheckbox
                valueAsObject={false}
                isMulti
                removeShadows
                backspaceRemovesValue={false}
                controlShouldRenderValue={false}
                menuIsOpen
                isClearable={false}
                placeholder={t("ui.search_placeholder")}
                value={value}
                onChange={(values: []) => {
                  onChange(values, "or");
                }}
              />
            </SelectOptionsWrapper>
          </Dropdown.List>
        </Dropdown>
      </>
    );
  }

  if (type === "VARIABLE_SELECT") {
    return (
      <Dropdown>
        <Dropdown.Button
          as={FilterButton}
          className="whitespace-nowrap"
          isSelected={!!(value as [])?.length}
          onClear={() => {
            onChange([], "or");
          }}
        >
          {name} {!!(value as [])?.length && ` (${(value as [])?.length})`}
        </Dropdown.Button>
        <Dropdown.List className="py-0 w-[380px]">
          <SelectOptionsWrapper fieldId={id}>
            <SelectMultiCheckbox
              valueAsObject={false}
              isMulti
              removeShadows
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              menuIsOpen
              isClearable={false}
              placeholder={t("ui.search_placeholder")}
              value={value}
              onChange={(values: []) => {
                onChange(values, "or");
              }}
            />
          </SelectOptionsWrapper>
        </Dropdown.List>
      </Dropdown>
    );
  }

  return (
    <Input
      className="w-min"
      onChange={(e) => onChange(e.target.value)}
      placeholder={name}
      value={
        typeof value === "string" || typeof value === "number"
          ? String(value)
          : ""
      }
      defaultValue={defaultValue}
    />
  );
};
