import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { GET_PROJECTS_FETCH_KEY } from "./useGetProjects";

const deleteProject = async (id: string) => {
  const response = await fetcher.delete(`/projects/${id}`);
  return response.data;
};

export const useDeleteProject = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (id: string) => {
      await deleteProject(id);
      return id;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_PROJECTS_FETCH_KEY],
      });
      toast.add({
        content: t("projects.project_deleted_successfully"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });

  return {
    deleteProject: mutateAsync,
    isPending,
  };
};

