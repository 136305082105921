import { useTenantData } from "@/features/session/useCases/useTenantData";
import { PageContainer } from "@/ui/containers/PageContainer";
import { BreadcrumbNav } from "@/ui/navbars/BreadcrumbNav";
import Wizard from "@/ui/Wizard";
import { Box, Button, Icon } from "@suit-ui/react";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdOutlineReportProblem } from "react-icons/md";
import { useLocalStorage } from "usehooks-ts";
import { CaseDataStep } from "../components/CaseWithConnectionSteps/CaseDataStep";
import { SearchCaseStep } from "../components/CaseWithConnectionSteps/SearchCaseStep";
import { SearchResultStepOld } from "../components/CaseWithConnectionSteps/SearchResultsStepOld";
import { SearchResultStep } from "../components/CaseWithConnectionSteps/SearchResultStep";
import { CaseWithConnectionForm } from "../types/CaseWithConnectionForm";
import { NoCaseResultsPlaceholder } from '../components/NoCaseResultsPlaceholder';
import { routes } from '../../../router';
import { useNavigate } from 'react-router-dom';

export function CaseWithConnectionPage() {
  const { t } = useTranslation();
  const [caseError, setCaseError] = useState<string | null>(null);
  const { getFFValue } = useTenantData();
  const FFEnableConnectCases = getFFValue("enable_connect_cases");
  const navigate = useNavigate();

  const [connectCaseFormResults] = useLocalStorage<
    CaseWithConnectionForm | undefined
  >("connectCaseFormResults", undefined);

  const methods = useForm<CaseWithConnectionForm>(
    FFEnableConnectCases
      ? {
          values: connectCaseFormResults,
        }
      : undefined,
  );

  const steps = useMemo(() => {
    return FFEnableConnectCases
      ? [
          <SearchResultStep />,
          <CaseDataStep onSaveFailed={(error) => setCaseError(error)} />,
        ]
      : [
          <SearchCaseStep />,
          <SearchResultStepOld />,
          <CaseDataStep onSaveFailed={(error) => setCaseError(error)} />,
        ];
  }, [FFEnableConnectCases]);

  if (FFEnableConnectCases && !connectCaseFormResults) {
    return <NoCaseResultsPlaceholder
            title={t("cases.header.connect_case")}
            description={t("cases.connect_search_not_found_description_cases")}
            onClick={() => navigate(routes.cases)}
            buttonLabel={t("cases.return_to_cases")} />
  }

  return (
    <FormProvider {...methods}>
      {caseError && (
        <Box className="flex items-center bg-danger-200 p-4 w-full">
          <Icon as={MdOutlineReportProblem} />

          <Box className="font-regular ml-2">{caseError}</Box>

          <Button
            variant="text"
            className="ml-auto font-semibold"
            onClick={() => setCaseError(null)}
          >
            {t("ui.close")}
          </Button>
        </Box>
      )}

      <PageContainer className="flex flex-col items-start">
        <Box className="flex justify-between mb-6">
          <BreadcrumbNav />
        </Box>

        <Wizard steps={steps} onBack={() => navigate(`${routes.cases}?showConnectModal=true`)}/>
      </PageContainer>
    </FormProvider>
  );
}
