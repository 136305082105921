import { PageTitle } from "@/ui/texts/PageTitle";
import { Box, Banner, FormControl, Button, Text, Select } from "@suit-ui/react";
import DynamicForm from "../DynamicForm";
import { useTranslation } from "react-i18next";
import { useGetCaseFinders } from "../../useCases/useGetCaseFinders";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { useValidateSearch } from "../../useCases/useValidateSearch";
import { CaseFinder } from "../../types/CaseFinder";
import { CaseSearch } from "../../types/CaseSearch";
import { usePollSearchResult } from "../../useCases/usePollSearchResult";
import { useSearch } from "../../useCases/useSearch";
import { CaseWithConnectionForm } from "../../types/CaseWithConnectionForm";
import { useNavigate } from "react-router-dom";
import { routes } from "@/router";
import { CredentialsSelect } from "../CredentialsSelect";
import { trackEvent } from "@/utils/analytics/trackEvent";

interface SearchCaseStepProps {
  onStepComplete?: () => void;
}

const SearchCaseStep = ({ onStepComplete }: SearchCaseStepProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedFinderId, setSelectedFinderId] = useState<string | null>(null);
  const { data: caseFinders, isLoading } = useGetCaseFinders();
  const methods = useFormContext<CaseWithConnectionForm>();
  const validateSearch = useValidateSearch();
  const search = useSearch();

  const {
    data: searchResultDataPolling,
    isLoading: isPollingSearchResult,
    invalidatePollSearchResult,
  } = usePollSearchResult(search?.data?.key);
  const [customDataInput, setCustomDataInput] = useState<{
    params: CaseSearch["params"];
    selectors: CaseSearch["selectors"];
    events: CaseSearch["events"];
  }>({ params: {}, selectors: {}, events: {} });

  const finderOptions = useMemo(
    () =>
      caseFinders?.map((finder) => ({
        label: finder.name,
        value: finder.id,
      })),
    [caseFinders],
  );

  const getFinderById = (id: string) =>
    caseFinders?.find((finder) => finder.id === id);

  const currentFinder = useMemo(
    () =>
      selectedFinderId ? (getFinderById(selectedFinderId) as CaseFinder) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFinderId],
  );

  const searchParamsParsed = (data: CaseWithConnectionForm) => {
    const search_params: CaseSearch["params"] = {};
    for (const key in data.search_params) {
      search_params[`search_params[${key}]`] = data.search_params[key];
    }

    return search_params;
  };

  const onSubmit = methods.handleSubmit(async (data) => {
    const search_params = searchParamsParsed(data);
    const responseValidate = await validateSearch.mutateAsync({
      finder: currentFinder!.code,
      search_params,
      credential_id: data.credential_id,
    });
    invalidatePollSearchResult();

    methods.reset({
      ...methods.getValues(),
      search_params: responseValidate.params,
    });

    trackEvent("Search case form submitted");

    if (responseValidate.errors.length) {
      invalidatePollSearchResult();
    } else {
      search.mutateAsync({
        finder: currentFinder!.code,
        search_params,
        credential_id: data.credential_id,
      });
    }
  });

  useEffect(() => {
    if (!searchResultDataPolling) {
      return;
    }

    const { rows, col_names, pagination, paginable, detail_cols } =
      searchResultDataPolling;
    if (!rows || rows.length === 0) {
      return;
    }

    methods.setValue("result_params", {
      rows,
      col_names,
      pagination,
      paginable,
      detail_cols,
    });

    methods.setValue("finder", currentFinder!.code);
    methods.setValue("credential_id", methods.getValues().credential_id);
    methods.setValue("search_params", searchParamsParsed(methods.getValues()));
    if (search?.data?.key) methods.setValue("searchKey", search.data.key);

    onStepComplete?.();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultDataPolling]);

  useEffect(() => {
    if (!currentFinder) {
      return;
    }

    const { params, selectors, events } = currentFinder.empty_search;
    setCustomDataInput({ params, selectors, events });

    methods.reset({
      search_params: params,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFinder]);

  return (
    <>
      <PageTitle>{t("cases.search_case")}</PageTitle>

      <Box className="flex justify-center w-full">
        <Box
          as="form"
          className="flex flex-col bg-neutral-50 p-8 rounded-sm shadow-xs mt-6 w-full max-w-screen-md gap-6"
          onSubmit={onSubmit}
        >
          {!!validateSearch.data &&
            validateSearch.data?.errors?.map((error) => (
              <Banner status="danger" key={error}>
                <span dangerouslySetInnerHTML={{ __html: error }} />
              </Banner>
            ))}

          {!validateSearch.data &&
            !validateSearch.isPending &&
            currentFinder?.empty_search?.errors?.map((error) => (
              <Banner key={error}>
                <span dangerouslySetInnerHTML={{ __html: error }} />
              </Banner>
            ))}

          {searchResultDataPolling &&
            searchResultDataPolling?.rows?.length === 0 && (
              <Banner>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("cases.search_case_no_results"),
                  }}
                />
              </Banner>
            )}

          {searchResultDataPolling?.errors?.map((error) => (
            <Banner status="danger">
              <span dangerouslySetInnerHTML={{ __html: error }} />
            </Banner>
          ))}

          <FormControl required>
            <FormControl.Label>
              {t("cases.search_finder_label")}
            </FormControl.Label>

            <Select
              isLoading={isLoading}
              options={finderOptions}
              valueAsObject={false}
              value={selectedFinderId}
              onChange={(finderId: string) => {
                setSelectedFinderId(finderId);

                // unregister prev search fields to avoid include it on the validateSearch payload.
                Object.keys(currentFinder?.empty_search.params || {}).forEach(
                  (field) => {
                    methods.unregister(`search_params[${field}]` as any);
                  },
                );
              }}
            />
          </FormControl>

          {currentFinder?.credential_type && (
            <FormControl>
              <FormControl.Label>{t("cases.credentials")}</FormControl.Label>

              <Controller
                control={methods.control}
                name="credential_id"
                render={({ field: { onChange, value } }) => (
                  <CredentialsSelect
                    value={value}
                    onChange={onChange}
                    credentialTypeFilter={currentFinder?.credential_type}
                  />
                )}
              />
            </FormControl>
          )}

          {currentFinder && (
            <DynamicForm
              id={currentFinder.id}
              params={customDataInput?.params}
              selectors={customDataInput?.selectors}
              events={customDataInput?.events}
              onValidateDependentFields={async () => {
                const data = methods.getValues();
                const search_params = searchParamsParsed(data);
                const responseValidate = await validateSearch.mutateAsync({
                  finder: currentFinder.code,
                  search_params,
                });

                const { params, selectors, events } = responseValidate;
                methods.reset({
                  ...data,
                  search_params: params,
                });
                setCustomDataInput({ params, selectors, events });
              }}
            />
          )}

          <Box className="flex items-center gap-5">
            <Text className="mr-auto">* {t("ui.required_fields")}</Text>

            <Button
              variant="text"
              onClick={() => {
                trackEvent("Search case form canceled");
                navigate(routes.cases);
              }}
            >
              {t("ui.cancel")}
            </Button>

            {!!currentFinder && (
              <Button
                type="submit"
                loading={
                  validateSearch.isPending ||
                  search.isPending ||
                  isPollingSearchResult
                }
              >
                {t("cases.search_case")}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { SearchCaseStep };
